import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Grid, Box, Typography } from "@mui/material";
import { useStores } from "../../../stores";
import { Dropdown as DropdownModel } from "../../../models/Dropdown";
import { DropdownTypes as DropdownTypesConstant } from "../../../constants/Dropdown";
import { GetDropdownByType as GetDropdownByTypeService } from "../../../services/Dropdown";
import DropdownSelection from "../../dropdowns/Selection";
import DatePicker from "../../inputs/DatePicker";
import InputTextArea from "../../inputs/TextArea";
import FormComplication from "./complications/Form";

interface Props {}

const Form = observer((props: Props) => {
  const { PostoperativeStore } = useStores();
  const [startLiquidDietDay, setStartLiquidDietDay] = useState<DropdownModel[]>(
    []
  );

  useEffect(() => {
    if (!PostoperativeStore.isReadOnly) getDropdownStartLiquidDietDay();
  }, [PostoperativeStore.isReadOnly]);

  const onInputChange = (key: string, value: any) => {
    const form: any = { ...PostoperativeStore.postoperative };
    form[key] = value;
    PostoperativeStore.onSetPostoperative({ ...form });
  };
  const getDropdownStartLiquidDietDay = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.postoperativeStartLiquidDietDay
    );
    response.success && setStartLiquidDietDay([...response.data]);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} marginTop={2}>
          <Box className="input-label">
            <Typography variant="body2">Start liquid diet day :</Typography>
          </Box>
          <DropdownSelection
            options={startLiquidDietDay}
            selected={PostoperativeStore.postoperative?.startLiquidDietDay}
            optionKey="value"
            valueKey="id"
            placeholder="Warfarin stop date (days)"
            isDisabled={PostoperativeStore.isReadOnly}
            isReadOnly={PostoperativeStore.isReadOnly}
            onSelected={(value: any) =>
              onInputChange("startLiquidDietDay", value)
            }
          />
        </Grid>
        <Grid item xs={12} md={6} marginTop={2}>
          <DatePicker
            label="Date of discharge"
            isRequire={false}
            dateSelected={PostoperativeStore.postoperative?.dateOfDischarge}
            isDisabled={PostoperativeStore.isReadOnly}
            isReadOnly={PostoperativeStore.isReadOnly}
            onDateSelected={(date: any) =>
              onInputChange("dateOfDischarge", date)
            }
          />
        </Grid>
      </Grid>
      <Box marginTop={4}>
        <FormComplication />
      </Box>

      {/* Detail */}
      <Box marginTop={4}>
        <Box className="input-label">
          <Typography variant="body2">Detail :</Typography>
        </Box>
        <InputTextArea
          placeholder="Detail"
          value={PostoperativeStore.postoperative.detail || ""}
          isDisabled={PostoperativeStore.isReadOnly}
          isReadOnly={PostoperativeStore.isReadOnly}
          handleOnChange={(value: string) => onInputChange("detail", value)}
        />
      </Box>
      {/* /.Detail */}
    </>
  );
});

export default Form;
