import dayjs from "dayjs";

const ValidateFormatEmail = (email: string) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
};
const ValidateFormatPassword = (password: string) => {
  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return regex.test(password);
};
const ValidateRangeOfDate = (fDate: string, lDate: string) => {
  const startDateDiff = dayjs(fDate).diff(dayjs(lDate), "day");
  const endDateDiff = dayjs(lDate).diff(dayjs(fDate), "day");
  let isStartDateValid = true;
  let isEndDateValid = true;
  startDateDiff > 0 && (isStartDateValid = false);
  endDateDiff < 0 && (isEndDateValid = false);
  return { isStartDateValid, isEndDateValid };
};
const ValidateFormatCardId = (cardId: string) => {
  const string: any = String(cardId).substring(0, 13);
  let sum = 0;
  let bit = 0;
  for (let i = 13; i > 1; i--) {
    sum += string[bit] * i;
    bit += 1;
  }
  const checkBit = 11 - (sum % 11);
  const unit = String(checkBit)[String(checkBit).length - 1];
  if (Number(unit) === Number(string[12])) {
    return { cardId: string, isValidCardId: true };
  } else {
    return { cardId: string, isValidCardId: false };
  }
};
const FormatInputNumber = (text: string) => {
  const string = text.replace(/[^0-9\.]/g, ""); //eslint-disable-line
  return string;
};
const FormatInputName = (text: string) => {
  const string = text.replace(
    /[0-9\๑\๒\๓\๔\๕\๖\๗\๘\๙\๐\@\_\.\-\,\/\!\#\$\%\^\&\*\)\(\+\=\<\>\{\}\[\]\:\;\'\"\|\~\`\\]/g, //eslint-disable-line
    ""
  );
  return string;
};
const FormatInputText = (text: string) => {
  // const string = text.replace(
  //   /[\!\#\$\%\^\&\*\)\(\+\=\<\>\{\}\[\]\:\;\'\"\|\~\`\\]/g, //eslint-disable-line
  //   ""
  // );
  return text;
};
const FormatInputHn = (text: string) => {
  const string = text.replace(/[^a-zA-Z0-9/-]/g, "").slice(0, 16); //eslint-disable-line
  return string;
};
const FormatDisplayHn = (text: string) => {
  // const isHasSlash = text.search("/");
  // const yearHn =
  //   isHasSlash < 0 ? text.slice(0, 2) : text.slice(isHasSlash + 1, 9);
  // const numberHn =
  //   isHasSlash < 0 ? text.slice(2, 9) : text.slice(0, isHasSlash);
  // const hnParsedInt = parseInt(numberHn);
  // return `${hnParsedInt}/${yearHn}`;
  return text;
};
const ValidateFormatHn = (hn: string) => {
  return hn.length >= 1 && hn.length <= 16;
};
const CalculateBmi = (weight: number, height: number) => {
  const heightMeter = height / 100;
  const bmi = weight / (heightMeter * heightMeter);
  return bmi.toFixed(2);
};

export {
  ValidateFormatEmail,
  ValidateFormatPassword,
  ValidateRangeOfDate,
  ValidateFormatCardId,
  FormatInputNumber,
  FormatInputName,
  FormatInputText,
  FormatInputHn,
  FormatDisplayHn,
  ValidateFormatHn,
  CalculateBmi,
};
