import React, { useState } from "react";
import "../../assets/styles/Input.scss";
import "../../assets/styles/Dropdown.scss";
import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Error as ErrorIcon,
  AddRounded as AddRoundedIcon,
} from "@mui/icons-material";
import { useDebouncedCallback } from "use-debounce";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";

type Props = {
  id: string;
  options: any[];
  selected: any;
  label?: string;
  placeholder?: string;
  optionLabelKey?: string;
  isRequire?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  isShowErrorMessage?: boolean;
  errorMessage?: string;
  errorIconSize?: number;
  debounce?: number;
  textNoResultAddButton?: string;
  onSelected: (value: any) => void;
  onInputChange: (value: string) => void;
  onClickAdd: any;
};

const SearchDropdown = (props: Props) => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = useDebouncedCallback((value) => {
    setInputValue(value !== "---- ตัวเลือก ----" ? value : "");
    props.onInputChange(value);
  }, props.debounce || 0);

  return (
    <>
      {props.label ? (
        <div className="input-label">
          <Typography variant="body2">{props.label}</Typography>&nbsp;
          {props.isRequire && (
            <Typography className="input-label-require" variant="body2">
              *&nbsp;
            </Typography>
          )}
          <Typography variant="body2">:</Typography>
        </div>
      ) : null}
      {!props.isReadOnly ? (
        <Autocomplete
          disablePortal
          disableClearable
          options={props.options}
          value={props.selected}
          disabled={props.isDisabled}
          onChange={(event, value) => props.onSelected(value)}
          onInputChange={(event, value) => handleInputChange(value)}
          getOptionLabel={(option) =>
            props.optionLabelKey ? option[props.optionLabelKey] : option
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={props.placeholder}
              className={props.isError ? "input-error" : ""}
            />
          )}
          isOptionEqualToValue={(option, value) => true}
          noOptionsText={
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                variant="body2"
                color={ColorsConstant.placeholder}
                fontWeight={400}
                minWidth={75}
                maxWidth={75}
              >
                No results
              </Typography>
              <Typography
                variant="body2"
                color={ColorsConstant.black}
                fontWeight={600}
                width={"100%"}
                paddingX={1}
                sx={{ wordBreak: "break-word" }}
              >
                “{inputValue}”
              </Typography>
              <Box marginLeft={"auto"} width={"100%"}>
                <Button
                  variant="text"
                  sx={{ fontWeight: "400 !important" }}
                  onClick={() => props.onClickAdd(inputValue)}
                >
                  <AddRoundedIcon /> {props.textNoResultAddButton || "Add"}
                </Button>
              </Box>
            </Box>
          }
        />
      ) : (
        <Box paddingTop={2} color={ColorsConstant.black}>
          <Typography variant="body1">
            {props.selected
              ? props.optionLabelKey
                ? props.selected[props.optionLabelKey]
                : props.selected
              : "-"}
          </Typography>
        </Box>
      )}
      {props.isError && props.isShowErrorMessage ? (
        <Stack
          className="error-message"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          marginTop={0.5}
        >
          <ErrorIcon sx={{ fontSize: props.errorIconSize || 24 }} />
          <Typography variant="subtitle2">{props.errorMessage}</Typography>
        </Stack>
      ) : null}
    </>
  );
};

export default SearchDropdown;
