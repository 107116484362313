import { Axios } from "../plugins";
import { CreateBody as CreateBodySurgeonModel } from "../models/Surgeon";

const search = async (name: string) => {
  try {
    const { data } = await Axios.get("/surgeons/search", { params: { name } });
    return {
      success: true,
      data: data?.data || [],
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const create = async (body: CreateBodySurgeonModel) => {
  try {
    const { data } = await Axios.post("/surgeons", body);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

export { search as SearchSurgeon, create as CreateSurgeon };
