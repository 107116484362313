import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { ColorectalSurgeryPreoperativeForm as ColorectalSurgeryPreoperativeFormConstant } from "../../../constants/Form";
import { DropdownTypes as DropdownTypesConstant } from "../../../constants/Dropdown";
import { InitialDropdown as InitialDropdownConstant } from "../../../constants/Form";
import { GetDropdownByType as GetDropdownByTypeService } from "../../../services/Dropdown";
import { Dropdown as DropdownModel } from "../../../models/Dropdown";
import { useStores } from "../../../stores";
import RadioSelection from "../../inputs/RadioSelection";
import DropdownSelection from "../../dropdowns/Selection";
import InputTextField from "../../inputs/TextField";

interface Props {}

const FormLiverMetastasis = observer((props: Props) => {
  const { PreoperativeStore } = useStores();
  const [liverMetastasisOptions, setLiverMetastasisOptions] = useState<
    DropdownModel[]
  >([]);

  useEffect(() => {
    !PreoperativeStore.isReadOnly && getDropdownLiverMetastasisOptions();
  }, [PreoperativeStore.isReadOnly]);

  const getDropdownLiverMetastasisOptions = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.preoperativeLiverMetastasis
    );
    response.success && setLiverMetastasisOptions([...response.data]);
  };
  const onInputChange = (key: string, value: any) => {
    const formObj: any = { ...PreoperativeStore.preoperative };
    const formIsInvalid: any = { ...PreoperativeStore.preoperativeIsInvalid };
    formObj[key] = value;
    formIsInvalid[key] && (formIsInvalid[key] = false);
    if (key === "liverMetastasis" && (!value || value === "false")) {
      formObj.liverMetastasisLabel = { ...InitialDropdownConstant };
      formIsInvalid.liverMetastasisLabel = false;
    }
    PreoperativeStore.onSetPreoperative(formObj);
    PreoperativeStore.onSetPreoperativeIsInvalid(formIsInvalid);
  };
  return (
    <Box id={ColorectalSurgeryPreoperativeFormConstant.liverMetastasis}>
      <Box
        className="input-label"
        color={ColorsConstant.black}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
      >
        <Typography variant="h6">Liver metastasis</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item marginTop={2} xs={12} md={4}>
          <Box className="input-label">
            <Typography variant="body2">Liver metastasis</Typography>
            <Typography className="input-label-require" variant="body2">
              &nbsp;*
            </Typography>
            <Typography variant="body2">&nbsp;:</Typography>
          </Box>
          <Box display="flex">
            <RadioSelection
              value="true"
              selectedValue={
                PreoperativeStore.preoperative?.liverMetastasis || ""
              }
              label="Yes"
              isDisabled={PreoperativeStore.isReadOnly}
              onChange={(value: any) => onInputChange("liverMetastasis", value)}
            />
            <RadioSelection
              value="false"
              selectedValue={
                PreoperativeStore.preoperative?.liverMetastasis || ""
              }
              label="No"
              isDisabled={PreoperativeStore.isReadOnly}
              onChange={(value: any) => onInputChange("liverMetastasis", value)}
            />
          </Box>
          {PreoperativeStore.preoperativeIsInvalid?.liverMetastasis && (
            <Stack
              className="error-message"
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              marginTop={0.5}
            >
              <ErrorIcon sx={{ fontSize: 16 }} />
              <Typography variant="subtitle2">
                Select a liver metastasis
              </Typography>
            </Stack>
          )}
        </Grid>
        <Grid item marginTop={2} xs={12} md={4}>
          <Box className="input-label">
            <Typography variant="body2">Liver metastasis if yes</Typography>
            <Typography className="input-label-require" variant="body2">
              &nbsp;*
            </Typography>
            <Typography variant="body2">&nbsp;:</Typography>
          </Box>
          <DropdownSelection
            options={liverMetastasisOptions}
            selected={PreoperativeStore.preoperative?.liverMetastasisLabel}
            optionKey="value"
            valueKey="id"
            placeholder="Liver metastasis if yes"
            errorMessage="Liver metastasis if yes is invalid"
            errorIconSize={16}
            isError={
              PreoperativeStore.preoperativeIsInvalid?.liverMetastasisLabel
            }
            isShowErrorMessage={
              PreoperativeStore.preoperativeIsInvalid?.liverMetastasisLabel
            }
            isDisabled={
              PreoperativeStore.isReadOnly ||
              PreoperativeStore.preoperative?.liverMetastasis !== "true"
            }
            isReadOnly={PreoperativeStore.isReadOnly}
            onSelected={(value: any) =>
              onInputChange("liverMetastasisLabel", value)
            }
          />
        </Grid>
        <Grid item marginTop={2} xs={12} md={4}>
          <InputTextField
            id="liverMetastasisDetail"
            label="Liver metastasis detail"
            placeholder="Liver metastasis detail"
            type="text"
            value={String(PreoperativeStore.preoperative.liverMetastasisDetail)}
            isDisabled={PreoperativeStore.isReadOnly}
            isReadOnly={PreoperativeStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("liverMetastasisDetail", value)
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
});

export default FormLiverMetastasis;
