import React from "react";
import { Box, Typography, Stack, Grid } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { useStores } from "../../../../stores";
import { Colors as ColorsConstant } from "../../../../constants/themes/Colors";
import {
  AdenocarcinomaAndSquamousDifferentiationOptions as AdenocarcinomaAndSquamousDifferentiationOptionsConstant,
  TissuePathologyKeys as TissuePathologyKeysConstant,
} from "../../../../constants/colorectalSurgery/Preoperative";
import InputTextField from "../../../inputs/TextField";
import CheckboxSelection from "../../../inputs/CheckboxSelection";
import RadioGroupSelectionInput from "../../../inputs/RadioGroupSelection";
import FormImageUploadTissuePathology from "./FormImageUploadTissuePathology";
import { observer } from "mobx-react";

type Props = {};

const FormTissuePathology = observer((props: Props) => {
  const { PostoperativeStore } = useStores();

  const onInputChange = (key: string, value: any) => {
    const form: any = { ...PostoperativeStore.postoperativeComplication };
    const formIsInvalid: any = {
      ...PostoperativeStore.postoperativeComplicationIsInvalid,
    };
    form[key] = value;
    formIsInvalid[key] = false;
    PostoperativeStore.onSetPostoperativeComplication({ ...form });
    PostoperativeStore.onSetPostoperativeComplicationIsInvalid({
      ...formIsInvalid,
    });
  };
  const onInputPostoperativeTissuePathologyChange = (
    value: boolean,
    index: number
  ) => {
    const forms = [...PostoperativeStore.postoperativeTissuePathologies];
    const formIsInvalid: any = {
      ...PostoperativeStore.postoperativeComplicationIsInvalid,
    };
    forms[index] = { ...forms[index], value };
    formIsInvalid.preoperativeTissuePathology = false;
    switch (forms[index].key) {
      case TissuePathologyKeysConstant.adenocarcinoma:
        formIsInvalid.adenocarcinomaDifferentiation = false;
        onInputChange("adenocarcinomaDifferentiation", "");
        break;
      case TissuePathologyKeysConstant.squamousCellCa:
        formIsInvalid.squamousCellCaDifferentiation = false;
        onInputChange("squamousCellCaDifferentiation", "");
        break;
      case TissuePathologyKeysConstant.other:
        formIsInvalid.otherDetail = false;
        onInputChange("otherDetail", "");
        break;
      default:
        break;
    }
    PostoperativeStore.onSetPostoperativeTissuePathologies([...forms]);
    PostoperativeStore.onSetPostoperativeComplicationIsInvalid(formIsInvalid);
  };

  const preoperativeTissuePathologySubInput = (key: string) => {
    switch (key) {
      case "adenocarcinoma":
        return (
          <Box marginLeft={{ xs: 4, md: 0 }}>
            <Box className="input-label">
              <Typography variant="body2">
                Adenocarcinoma Differentiation
              </Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*
              </Typography>
              <Typography variant="body2">&nbsp;:</Typography>
            </Box>
            <RadioGroupSelectionInput
              options={AdenocarcinomaAndSquamousDifferentiationOptionsConstant}
              selectedValue={
                PostoperativeStore.postoperativeComplication
                  ?.adenocarcinomaDifferentiation || ""
              }
              labelKey={"label"}
              gridColumnXs={12}
              gridColumnMd={4}
              gridColumnLg={4}
              isDisabled={PostoperativeStore.isReadOnly}
              iconSize={20}
              onChange={(value: string) =>
                onInputChange("adenocarcinomaDifferentiation", value)
              }
            />
            {PostoperativeStore.postoperativeComplicationIsInvalid
              .adenocarcinomaDifferentiation && (
              <Stack
                className="error-message"
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                marginTop={0.5}
              >
                <ErrorIcon sx={{ fontSize: 16 }} />
                <Typography variant="subtitle2">
                  Select a adenocarcinoma differentiation
                </Typography>
              </Stack>
            )}
          </Box>
        );
      case "squamousCellCa":
        return (
          <Box marginLeft={{ xs: 4, md: 0 }}>
            <Box className="input-label">
              <Typography variant="body2">
                Squamous cell CA differentiation
              </Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*
              </Typography>
              <Typography variant="body2">&nbsp;:</Typography>
            </Box>
            <RadioGroupSelectionInput
              options={AdenocarcinomaAndSquamousDifferentiationOptionsConstant}
              selectedValue={
                PostoperativeStore.postoperativeComplication
                  ?.squamousCellCaDifferentiation || ""
              }
              labelKey={"label"}
              gridColumnXs={12}
              gridColumnMd={4}
              gridColumnLg={4}
              isDisabled={PostoperativeStore.isReadOnly}
              iconSize={20}
              onChange={(value: string) =>
                onInputChange("squamousCellCaDifferentiation", value)
              }
            />
            {PostoperativeStore.postoperativeComplicationIsInvalid
              .squamousCellCaDifferentiation && (
              <Stack
                className="error-message"
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                marginTop={0.5}
              >
                <ErrorIcon sx={{ fontSize: 16 }} />
                <Typography variant="subtitle2">
                  Select a squamous cell CA differentiation
                </Typography>
              </Stack>
            )}
          </Box>
        );
      case "other":
        return (
          <Box marginLeft={{ xs: 4, md: 0 }}>
            <InputTextField
              id="otherDetail"
              label="Other detail"
              placeholder="Other detail"
              type="text"
              value={
                PostoperativeStore.postoperativeComplication.otherDetail || ""
              }
              errorMessage="Others detail is invalid"
              isShowErrorMessage={
                PostoperativeStore.postoperativeComplicationIsInvalid
                  ?.otherDetail
              }
              isError={
                PostoperativeStore.postoperativeComplicationIsInvalid
                  ?.otherDetail
              }
              errorIconSize={16}
              isDisabled={PostoperativeStore.isReadOnly}
              isReadOnly={PostoperativeStore.isReadOnly}
              isRequire={true}
              handleOnChange={(value: string) =>
                onInputChange("otherDetail", value)
              }
            />
          </Box>
        );
      default:
        break;
    }
  };

  return (
    <>
      <Box color={ColorsConstant.neutral} marginBottom={1} marginTop={4}>
        <Box className="input-label">
          <Typography variant="body2">
            Postoperative tissue pathology :
          </Typography>
        </Box>
      </Box>
      {PostoperativeStore.postoperativeTissuePathologies?.map(
        (postoperativeTissuePathology, index) => {
          return (
            <Grid key={index} container spacing={1} marginBottom={1}>
              <Grid item xs={12} md={6} key={index}>
                <CheckboxSelection
                  label={postoperativeTissuePathology.label}
                  isChecked={postoperativeTissuePathology.value}
                  isDisabled={PostoperativeStore.isReadOnly}
                  onChange={(value: boolean) =>
                    onInputPostoperativeTissuePathologyChange(value, index)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {postoperativeTissuePathology.value &&
                  preoperativeTissuePathologySubInput(
                    postoperativeTissuePathology.key
                  )}
              </Grid>
            </Grid>
          );
        }
      )}
      <Box marginTop={3}>
        <FormImageUploadTissuePathology />
      </Box>
    </>
  );
});

export default FormTissuePathology;
