import { observable, makeAutoObservable, action } from "mobx";
import dayjs from "dayjs";
import {
  Patient as PatientModel,
  PatientIsInValid as PatientIsInValidModel,
} from "../models/Patient";
import {
  Nationality as NationalityConstant,
  IdCardType as IdCardTypeConstant,
  GenderOptions as GenderOptionsConstant,
} from "../constants/Personal";
import { OnGetPatientById as OnGetPatientByIdService } from "../services/Patient";
import { ValidatePastDate as ValidatePastDateUtil } from "../utils/FormatDate";
import { FormatDisplayHn as FormatDisplayHnUtil } from "../utils/InputValidation";

class PatientStore {
  private initialPatient: PatientModel = {
    id: "",
    nationality: NationalityConstant.thai,
    idCardType: IdCardTypeConstant.idCard,
    country: {
      id: "",
      name: "",
      createdAt: "",
      updatedAt: "",
    },
    idCard: "",
    hn: "",
    titleName: {
      id: "",
      name: "",
      createdAt: "",
      updatedAt: "",
    },
    titleNameMisc: "",
    firstName: "",
    lastName: "",
    middleName: "",
    birthDate: "",
    gender: {
      label: "",
      value: "",
    },
    reimbursement: {
      id: "",
      name: "",
      createdAt: "",
      updatedAt: "",
    },
    isActive: true,
    admissionDate: "",
    remark: "",
    createdAt: "",
    updatedAt: "",
  };
  private initialPatientIsInvalid: PatientIsInValidModel = {
    country: false,
    idCard: false,
    hn: false,
    titleName: false,
    firstName: false,
    lastName: false,
    birthDate: false,
    gender: false,
    admissionDate: false,
  };

  @observable patient: PatientModel = {
    ...this.initialPatient,
  };
  @observable patientIsInvalid: PatientIsInValidModel = {
    ...this.initialPatientIsInvalid,
  };
  @observable isEditing: boolean = false;
  @observable isReadOnly: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  onSetPatient(value: PatientModel) {
    this.patient = { ...value };
  }
  @action
  onSetPatientIsInvalid(value: PatientIsInValidModel) {
    this.patientIsInvalid = { ...value };
  }
  @action
  onClearStore() {
    this.patient = this.initialPatient;
    this.patientIsInvalid = this.initialPatientIsInvalid;
    this.isEditing = false;
    this.isReadOnly = true;
  }
  @action
  onSetIsEditing(value: boolean) {
    this.isEditing = value;
  }
  @action
  onSetIsReadOnly(value: boolean) {
    this.isReadOnly = value;
  }
  @action
  onValidatePatientForm() {
    const formIsInvalidObj: any = { ...this.patientIsInvalid };
    formIsInvalidObj.country = Boolean(!this.patient.country?.id);
    formIsInvalidObj.idCard = Boolean(
      !this.patient.idCard || this.patientIsInvalid.idCard
    );
    formIsInvalidObj.hn = Boolean(!this.patient.hn || this.patientIsInvalid.hn);
    formIsInvalidObj.titleName = Boolean(!this.patient.titleName?.id);
    formIsInvalidObj.firstName = Boolean(!this.patient.firstName);
    formIsInvalidObj.lastName = Boolean(!this.patient.lastName);
    formIsInvalidObj.birthDate = Boolean(
      !this.patient.birthDate ||
        (this.patient.birthDate &&
          !ValidatePastDateUtil(this.patient.birthDate))
    );
    formIsInvalidObj.gender = Boolean(!this.patient.gender?.value);
    formIsInvalidObj.admissionDate = Boolean(
      !this.patient.admissionDate ||
        (this.patient.admissionDate &&
          !ValidatePastDateUtil(this.patient.admissionDate))
    );
    this.onSetPatientIsInvalid(formIsInvalidObj);
    return Object.values(formIsInvalidObj).every((v) => v === false);
  }
  @action
  onGetPatientBody() {
    const {
      nationality,
      idCardType,
      country,
      idCard,
      hn,
      titleName,
      titleNameMisc,
      firstName,
      lastName,
      middleName,
      birthDate,
      gender,
      admissionDate,
      reimbursement,
      remark,
      isActive,
    } = this.patient;
    const body = {
      nationality,
      idCardType,
      country: country.id,
      idCard,
      hn,
      titleName: titleName.id,
      titleNameMisc,
      firstName: firstName,
      lastName: lastName,
      middleName: middleName,
      birthDate: `${dayjs(birthDate)
        .add(-7, "hour")
        .format("YYYY-MM-DD HH:mm:ss")}`,
      gender: gender.value,
      admissionDate: `${dayjs(admissionDate)
        .add(-7, "hour")
        .format("YYYY-MM-DD HH:mm:ss")}`,
      ...((reimbursement.id || reimbursement.id === null) && {
        reimbursement: reimbursement.id,
      }),
      remark,
      isActive,
    };
    return body;
  }
  @action
  async GetPatientById(id: string) {
    const { success, data }: { success: boolean; data: PatientModel } =
      await OnGetPatientByIdService(id);
    success && this.MappingPatientInformation(data);
  }
  @action
  MappingPatientInformation(patient: PatientModel) {
    const genderValue = GenderOptionsConstant.find(
      (option) => patient?.gender === option.value
    );
    const data = {
      ...patient,
      hn: (patient?.hn && FormatDisplayHnUtil(patient?.hn)) || "",
      middleName: patient.middleName || "",
      remark: patient.remark || "",
      birthDate: dayjs(patient.birthDate),
      gender: genderValue || {
        label: "",
        value: "",
      },
      admissionDate: dayjs(patient.admissionDate),
      titleNameMisc: patient.titleNameMisc || "",
      reimbursement: patient.reimbursement || {
        id: "",
        name: "",
        createdAt: "",
        updatedAt: "",
      },
    };
    this.onSetPatient({ ...data });
  }
}
export default PatientStore;
