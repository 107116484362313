import React from "react";
import { FormControlLabel, Checkbox, Box, FormGroup } from "@mui/material";
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  Square as SquareIcon,
} from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";

interface Props {
  label: string;
  isChecked: boolean;
  isDisabled: boolean;
  iconSize?: number;
  onChange: (checked: boolean) => void;
}

const CheckboxSelection = (props: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    props.onChange(value);
  };

  return (
    <FormGroup sx={{ display: "block" }} onChange={handleChange}>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.isChecked}
            checkedIcon={
              <Box
                color={ColorsConstant.focused}
                display="flex"
                alignItems="center"
              >
                <CheckBoxIcon
                  sx={{
                    fontSize: props.iconSize || 20,
                    fill: props.isDisabled
                      ? ColorsConstant.mainIcon
                      : ColorsConstant.focused,
                  }}
                />
              </Box>
            }
            icon={
              props.isDisabled ? (
                <Box
                  color={ColorsConstant.disable}
                  display="flex"
                  alignItems="center"
                >
                  <SquareIcon
                    sx={{
                      fontSize: props.iconSize || 20,
                      fill: ColorsConstant.disable,
                    }}
                  />
                </Box>
              ) : (
                <Box
                  color={ColorsConstant.focused}
                  display="flex"
                  alignItems="center"
                >
                  <CheckBoxOutlineBlankIcon
                    sx={{
                      fontSize: props.iconSize || 20,
                      fill: ColorsConstant.mainIcon,
                    }}
                  />
                </Box>
              )
            }
            disabled={props.isDisabled}
          />
        }
        label={props.label}
        disabled={props.isDisabled}
      />
    </FormGroup>
  );
};

export default CheckboxSelection;
