import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Grid, Box, Typography } from "@mui/material";
import { useStores } from "../../../stores";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { DropdownTypes as DropdownTypesConstant } from "../../../constants/Dropdown";
import { Dropdown as DropdownModel } from "../../../models/Dropdown";
import { GetDropdownByType as GetDropdownByTypeService } from "../../../services/Dropdown";
import InputNumberField from "../../inputs/NumberField";
import DropdownSelection from "../../dropdowns/Selection";

interface Props {}

const FormMargin = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();
  const [grosslyMargins, setGrosslyMargins] = useState<DropdownModel[]>([]);

  useEffect(() => {
    if (!OperativeNoteStore.isReadOnly) getDropdownGrosslyMargins();
  }, [OperativeNoteStore.isReadOnly]);

  const getDropdownGrosslyMargins = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.operativeNoteGrosslyMargin
    );
    response.success && setGrosslyMargins([...response.data]);
  };
  const onInputChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.margin };
    form[key] = value;
    OperativeNoteStore.onSetMargin({ ...form });
  };

  return (
    <>
      <Box
        id={ColorectalSurgeryOperativeNoteFormConstant.margin}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Margin</Typography>
      </Box>
      <Grid container spacing={2} marginTop={0}>
        <Grid item xs={12} md={4} marginTop={1}>
          <InputNumberField
            id="distalMargin"
            label="Distal margin (cm.)"
            placeholder="Distal margin (cm.)"
            isRequire={false}
            isReadOnly={OperativeNoteStore.isReadOnly}
            value={OperativeNoteStore.margin?.distalMargin}
            handleOnChange={(value: number | string) => {
              onInputChange("distalMargin", value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} marginTop={1}>
          <InputNumberField
            id="proximalMargin"
            label="Proximal margin (cm.)"
            placeholder="Proximal margin (cm.)"
            isRequire={false}
            isReadOnly={OperativeNoteStore.isReadOnly}
            value={OperativeNoteStore.margin?.proximalMargin}
            handleOnChange={(value: number | string) => {
              onInputChange("proximalMargin", value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Grossly margin :</Typography>
          </Box>
          <DropdownSelection
            options={grosslyMargins}
            selected={OperativeNoteStore.margin?.grosslyMargin}
            optionKey="value"
            valueKey="id"
            placeholder="Grossly margin"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) => onInputChange("grosslyMargin", value)}
          />
        </Grid>
      </Grid>
    </>
  );
});

export default FormMargin;
