import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Grid, Box, Typography } from "@mui/material";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { DropdownTypes as DropdownTypesConstant } from "../../../constants/Dropdown";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { GetDropdownByType as GetDropdownByTypeService } from "../../../services/Dropdown";
import { Dropdown as DropdownModel } from "../../../models/Dropdown";
import { useStores } from "../../../stores";
import DropdownSelection from "../../dropdowns/Selection";
import CheckboxGroupSelection from "../../inputs/CheckboxGroupSelection";

interface Props {}

const FormDiagnosis = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();
  const [locations, setLocations] = useState<DropdownModel[]>([]);

  useEffect(() => {
    if (!OperativeNoteStore.isReadOnly) getDropdownColorectalSurgery();
  }, [OperativeNoteStore.isReadOnly]);

  const getDropdownColorectalSurgery = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.operativeNoteColorectalSurgery
    );
    response.success && setLocations([...response.data]);
  };
  const onInputChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.operativeNote };
    const formIsInvalid: any = { ...OperativeNoteStore.operativeNoteIsInvalid };
    form[key] = value;
    formIsInvalid[key] && (formIsInvalid[key] = false);
    OperativeNoteStore.onSetOperativeNote({ ...form });
    OperativeNoteStore.onSetOperativeNoteIsInvalid({ ...formIsInvalid });
  };
  const onInputDiagnosisLocations = (value: boolean, index: number) => {
    const forms: any = [...OperativeNoteStore.diagnosisLocations];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetDiagnosisLocations(forms);
  };

  return (
    <>
      <Box
        id={ColorectalSurgeryOperativeNoteFormConstant.operativeInformation}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Diagnosis</Typography>
      </Box>
      <Grid
        id={ColorectalSurgeryOperativeNoteFormConstant.diagnosis}
        container
        spacing={2}
        marginTop={1}
      >
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">Colorectal surgery :</Typography>
          </Box>
          <DropdownSelection
            options={locations}
            selected={OperativeNoteStore.operativeNote?.colorectalSurgery}
            optionKey="value"
            valueKey="id"
            placeholder="Colorectal surgery"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) =>
              onInputChange("colorectalSurgery", value)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">Location :</Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.diagnosisLocations}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={6}
            gridColumnLg={6}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputDiagnosisLocations(value, index)
            }
          />
        </Grid>
      </Grid>
    </>
  );
});

export default FormDiagnosis;
