import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { ColorectalSurgeryPreoperativeForm as ColorectalSurgeryPreoperativeFormConstant } from "../../../constants/Form";
import { DropdownTypes as DropdownTypesConstant } from "../../../constants/Dropdown";
import { InitialDropdown as InitialDropdownConstant } from "../../../constants/Form";
import { GetDropdownByType as GetDropdownByTypeService } from "../../../services/Dropdown";
import { Dropdown as DropdownModel } from "../../../models/Dropdown";
import { useStores } from "../../../stores";
import RadioSelection from "../../inputs/RadioSelection";
import DropdownSelection from "../../dropdowns/Selection";
import InputTextField from "../../inputs/TextField";

interface Props {}

const FormOtherMetastasis = observer((props: Props) => {
  const { PreoperativeStore } = useStores();
  const [otherMetastasisOptions, setOtherMetastasisOptions] = useState<
    DropdownModel[]
  >([]);

  useEffect(() => {
    !PreoperativeStore.isReadOnly && getDropdownOtherMetastasis();
  }, [PreoperativeStore.isReadOnly]);

  const getDropdownOtherMetastasis = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.preoperativeOtherMetastasis
    );
    response.success && setOtherMetastasisOptions([...response.data]);
  };
  const onInputChange = (key: string, value: any) => {
    const formObj: any = { ...PreoperativeStore.preoperative };
    const formIsInvalid: any = { ...PreoperativeStore.preoperativeIsInvalid };
    formObj[key] = value;
    formIsInvalid[key] && (formIsInvalid[key] = false);
    if (key === "otherMetastasis" && (!value || value === "false")) {
      formObj.otherMetastasisLabel = { ...InitialDropdownConstant };
      formIsInvalid.otherMetastasisLabel = false;
    }
    PreoperativeStore.onSetPreoperative(formObj);
    PreoperativeStore.onSetPreoperativeIsInvalid(formIsInvalid);
  };

  return (
    <Box id={ColorectalSurgeryPreoperativeFormConstant.otherMetastasis}>
      <Box
        className="input-label"
        color={ColorsConstant.black}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
      >
        <Typography variant="h6">Other metastasis</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item marginTop={2} xs={12} md={4}>
          <Box className="input-label">
            <Typography variant="body2">Other metastasis</Typography>
            <Typography className="input-label-require" variant="body2">
              &nbsp;*
            </Typography>
            <Typography variant="body2">&nbsp;:</Typography>
          </Box>
          <Box display="flex">
            <RadioSelection
              value="true"
              selectedValue={
                PreoperativeStore.preoperative?.otherMetastasis || ""
              }
              label="Yes"
              isDisabled={PreoperativeStore.isReadOnly}
              onChange={(value: any) => onInputChange("otherMetastasis", value)}
            />
            <RadioSelection
              value="false"
              selectedValue={
                PreoperativeStore.preoperative?.otherMetastasis || ""
              }
              label="No"
              isDisabled={PreoperativeStore.isReadOnly}
              onChange={(value: any) => onInputChange("otherMetastasis", value)}
            />
          </Box>
          {PreoperativeStore.preoperativeIsInvalid?.otherMetastasis && (
            <Stack
              className="error-message"
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              marginTop={0.5}
            >
              <ErrorIcon sx={{ fontSize: 16 }} />
              <Typography variant="subtitle2">
                Select a other metastasis
              </Typography>
            </Stack>
          )}
        </Grid>
        <Grid item marginTop={2} xs={12} md={4}>
          <Box className="input-label">
            <Typography variant="body2">Other metastasis if yes</Typography>
            <Typography className="input-label-require" variant="body2">
              &nbsp;*
            </Typography>
            <Typography variant="body2">&nbsp;:</Typography>
          </Box>
          <DropdownSelection
            options={otherMetastasisOptions}
            selected={PreoperativeStore.preoperative?.otherMetastasisLabel}
            optionKey="value"
            valueKey="id"
            placeholder="Other metastasis if yes"
            errorMessage="Other metastasis if yes is invalid"
            errorIconSize={16}
            isError={
              PreoperativeStore.preoperativeIsInvalid?.otherMetastasisLabel
            }
            isShowErrorMessage={
              PreoperativeStore.preoperativeIsInvalid?.otherMetastasisLabel
            }
            isDisabled={
              PreoperativeStore.isReadOnly ||
              PreoperativeStore.preoperative?.otherMetastasis !== "true"
            }
            isReadOnly={PreoperativeStore.isReadOnly}
            onSelected={(value: any) =>
              onInputChange("otherMetastasisLabel", value)
            }
          />
        </Grid>
        <Grid item marginTop={2} xs={12} md={4}>
          <InputTextField
            id="otherMetastasisDetail"
            label="Other metastasis detail"
            placeholder="Other metastasis detail"
            type="text"
            value={String(PreoperativeStore.preoperative.otherMetastasisDetail)}
            isDisabled={PreoperativeStore.isReadOnly}
            isReadOnly={PreoperativeStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("otherMetastasisDetail", value)
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
});

export default FormOtherMetastasis;
