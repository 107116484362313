import { FileDownload as FileDownloadService } from "../services/Storage";
import { isHeic, heicTo } from "heic-to";

const OpenFileFromObjectUrlInNewTab = (source: any) => {
  const downloadUrl = window.URL.createObjectURL(source);
  window.open(downloadUrl);
};

const DownloadFileFromUrl = async (fileName: string, tenant: string) => {
  const response = await FileDownloadService(fileName, { tenant });
  const downloadUrl = window.URL.createObjectURL(response.data);
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const ConvertFileToBase64 = async (file: File, callback: Function) => {
  const fileIsHeic = await isHeic(file);
  const reader = new FileReader();
  if (!fileIsHeic && file.type !== "image/heif") {
    reader.readAsDataURL(file);
    reader.onload = function () {
      callback(reader.result);
    };
  } else {
    const convertHeic = await heicTo({
      blob: file,
      type: "image/jpeg",
      quality: 0.5,
    });
    reader.readAsDataURL(convertHeic);
    reader.onload = function () {
      callback(reader.result);
    };
  }
};

export {
  OpenFileFromObjectUrlInNewTab,
  DownloadFileFromUrl,
  ConvertFileToBase64,
};
