import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Box, Typography, Grid } from "@mui/material";
import { useStores } from "../../stores";
import {
  Nationality as NationalityConstant,
  NationalityOptions as NationalityOptionsConstant,
  IdCardType as IdCardTypeConstant,
  IdCardTypeOptions as IdCardTypeOptionsConstant,
  GenderOptions as GenderOptionsConstant,
  TitleNameOtherId as TitleNameOtherIdConstant,
} from "../../constants/Personal";
import RadioGroupSelectionInput from "../../components/inputs/RadioGroupSelection";
import DropdownSelection from "../../components/dropdowns/Selection";
import InputTextField from "../../components/inputs/TextField";
import DatePicker from "../../components/inputs/DatePicker";
import InputTextArea from "../../components/inputs/TextArea";
import {
  GetAllCountries as GetAllCountriesService,
  GetAllTitleNames as GetAllTitleNamesService,
  GetAllReimbursement as GetAllReimbursementService,
} from "../../services/Patient";
import {
  ValidateFormatCardId as ValidateFormatCardIdUtil,
  FormatDisplayHn as FormatDisplayHnUtil,
  ValidateFormatHn as ValidateFormatHnUtil,
} from "../../utils/InputValidation";
import { ValidatePastDate as ValidatePastDateUtil } from "../../utils/FormatDate";

interface Props {}

const Form = observer((props: Props) => {
  const { PatientStore } = useStores();
  const [idCardTypes, setIdCardTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [titleNames, setTitleNames] = useState([]);
  const [reimbursements, setReimbursement] = useState<any>([]);
  const [countryThai, setCountryThai] = useState(null);

  let onGetAllCountries = () => {};

  useEffect(() => {
    if (!PatientStore.isReadOnly) {
      onGetAllCountries();
      onGetAllTitleNames();
      onGetAllReimbursementService();
    }
  }, [PatientStore.isReadOnly]);

  useEffect(() => {
    const idCardTypeMapping: any = IdCardTypeOptionsConstant.map((item) => {
      return { ...item, disabled: item.value !== IdCardTypeConstant.idCard };
    });
    setIdCardTypes(idCardTypeMapping);
  }, []);

  onGetAllCountries = async () => {
    const response = await GetAllCountriesService();
    if (response.success) {
      const findThailand = response.data?.find(
        (item: any) => item.name === "Thailand"
      );
      setCountries(response.data);
      findThailand && setCountryThai(findThailand);
      !PatientStore.isReadOnly &&
        !PatientStore.isEditing &&
        PatientStore.patient?.idCardType === IdCardTypeConstant.idCard &&
        findThailand &&
        onInputChange("country", findThailand);
    }
  };
  const onGetAllTitleNames = async () => {
    const response = await GetAllTitleNamesService();
    response.success && setTitleNames(response.data);
  };
  const onGetAllReimbursementService = async () => {
    const response = await GetAllReimbursementService();
    response.success && setReimbursement(response.data);
  };
  const handleNationalityChange = (value: string) => {
    let idCardTypeMapping: any;
    onInputChange("nationality", value);
    if (value === NationalityConstant.foreigner) {
      onInputChange("idCardType", IdCardTypeConstant.passport);
      idCardTypeMapping = IdCardTypeOptionsConstant.map((item) => {
        return { ...item, disabled: item.value === IdCardTypeConstant.idCard };
      });
      setIdCardTypes(idCardTypeMapping);
    } else if (NationalityConstant.thai) {
      onInputChange("idCardType", IdCardTypeConstant.idCard);
      idCardTypeMapping = IdCardTypeOptionsConstant.map((item) => {
        return { ...item, disabled: item.value !== IdCardTypeConstant.idCard };
      });
      setIdCardTypes(idCardTypeMapping);
    }
  };
  const onInputChange = (key: string, value: any) => {
    const formObj: any = { ...PatientStore.patient };
    const formIsInvalidObj: any = { ...PatientStore.patientIsInvalid };
    formObj[key] = value;
    formIsInvalidObj[key] = false;
    switch (key) {
      case "idCard":
        // Id card validation
        if (PatientStore.patient.idCardType === IdCardTypeConstant.idCard) {
          const validateCardId: any = ValidateFormatCardIdUtil(value);
          formObj.idCard = validateCardId.cardId;
          formIsInvalidObj.idCard = !validateCardId.isValidCardId;
        } else {
          onInputChange("cardId", value);
        }
        // /.Id card validation
        break;
      case "nationality":
        formObj.idCard = "";
        formIsInvalidObj.idCard = false;
        value === NationalityConstant.thai &&
          countryThai &&
          (formObj.country = countryThai);
        break;
      case "titleName":
        formObj.titleNameMisc = "";
        break;
      case "birthDate":
        value && (formIsInvalidObj.birthDate = !ValidatePastDateUtil(value));
        break;
      case "admissionDate":
        value &&
          (formIsInvalidObj.admissionDate = !ValidatePastDateUtil(value));
        break;
      case "hn":
        value && (formIsInvalidObj.hn = !ValidateFormatHnUtil(value));
        break;
      default:
        break;
    }
    PatientStore.onSetPatient(formObj);
    PatientStore.onSetPatientIsInvalid(formIsInvalidObj);
  };

  return (
    <>
      {/* Nationality */}
      <Box>
        <Box className="input-label">
          <Typography variant="body2">Nationality</Typography>{" "}
          <Typography className="input-label-require" variant="body2">
            &nbsp;*
          </Typography>
          <Typography variant="body2">&nbsp;:</Typography>
        </Box>
        <RadioGroupSelectionInput
          options={NationalityOptionsConstant}
          selectedValue={PatientStore.patient.nationality}
          labelKey={"label"}
          gridColumnXs={12}
          gridColumnMd={6}
          gridColumnLg={3}
          isDisabled={PatientStore.isReadOnly || PatientStore.isEditing}
          onChange={handleNationalityChange}
        />
      </Box>
      {/* /.Nationality */}

      {/* Card type */}
      <Box marginTop={4}>
        <Box className="input-label">
          <Typography variant="body2">Type of ID card</Typography>{" "}
          <Typography className="input-label-require" variant="body2">
            &nbsp;*
          </Typography>
          <Typography variant="body2">&nbsp;:</Typography>
        </Box>
        <RadioGroupSelectionInput
          options={idCardTypes}
          selectedValue={PatientStore.patient.idCardType}
          labelKey={"label"}
          gridColumnXs={12}
          gridColumnMd={6}
          gridColumnLg={3}
          isDisabled={PatientStore.isReadOnly || PatientStore.isEditing}
          onChange={(value: string) => onInputChange("idCardType", value)}
        />
      </Box>
      {/* /.Card type */}
      <Grid container spacing={2}>
        {/* Country */}
        <Grid item xs={12} md={4} marginTop={4}>
          <Box className="input-label">
            <Typography variant="body2">Country</Typography>{" "}
            <Typography className="input-label-require" variant="body2">
              &nbsp;*
            </Typography>
            <Typography variant="body2">&nbsp;:</Typography>
          </Box>
          <DropdownSelection
            options={countries}
            selected={PatientStore.patient?.country}
            optionKey="name"
            valueKey="id"
            placeholder="Country"
            isError={PatientStore.patientIsInvalid?.country}
            isShowErrorMessage={PatientStore.patientIsInvalid?.country}
            errorMessage="Country is invalid"
            isDisabled={
              PatientStore.isReadOnly ||
              PatientStore.patient?.idCardType === IdCardTypeConstant.idCard ||
              PatientStore.isEditing
            }
            isReadOnly={PatientStore.isReadOnly}
            onSelected={(value: any) => onInputChange("country", value)}
          />
        </Grid>
        {/* /.Country */}

        {/* Id card */}
        <Grid item xs={12} md={4} marginTop={4}>
          <InputTextField
            id="cardId"
            label={
              (PatientStore.patient.idCardType === IdCardTypeConstant.idCard &&
                "Identification card number") ||
              (PatientStore.patient.idCardType ===
                IdCardTypeConstant.passport &&
                "Passport number") ||
              (PatientStore.patient.idCardType ===
                IdCardTypeConstant.foreignerId &&
                "Foreigner identification number") ||
              ""
            }
            placeholder={
              (PatientStore.patient.idCardType === IdCardTypeConstant.idCard &&
                "Identification card number") ||
              (PatientStore.patient.idCardType ===
                IdCardTypeConstant.passport &&
                "Passport number") ||
              (PatientStore.patient.idCardType ===
                IdCardTypeConstant.foreignerId &&
                "Foreigner identification number") ||
              ""
            }
            type={
              PatientStore.patient?.idCardType === IdCardTypeConstant.idCard
                ? "number"
                : "text"
            }
            value={PatientStore.patient?.idCard}
            errorMessage="Identification card number is invalid"
            isShowErrorMessage={PatientStore.patientIsInvalid?.idCard}
            isError={PatientStore.patientIsInvalid?.idCard}
            isDisabled={PatientStore.isReadOnly || PatientStore.isEditing}
            isReadOnly={PatientStore.isReadOnly}
            isRequire={true}
            handleOnChange={(value: string) => onInputChange("idCard", value)}
            handleOnKeyDown={(key: string) => {}}
          />
        </Grid>
        {/* /.Id card */}

        {/* Hospital number */}
        <Grid item xs={12} md={4} marginTop={4}>
          <InputTextField
            id="hospitalNumber"
            label="Hospital number"
            placeholder="Ex. 000001/66"
            type="hn"
            value={
              !PatientStore.isReadOnly
                ? PatientStore.patient?.hn
                : PatientStore.patient?.hn &&
                  FormatDisplayHnUtil(PatientStore.patient?.hn)
            }
            errorMessage="Hospital number is invalid"
            isShowErrorMessage={PatientStore.patientIsInvalid?.hn}
            isError={PatientStore.patientIsInvalid?.hn}
            isDisabled={PatientStore.isReadOnly || PatientStore.isEditing}
            isReadOnly={PatientStore.isReadOnly}
            isRequire={true}
            handleOnChange={(value: string) => onInputChange("hn", value)}
          />
        </Grid>
        {/* /.Hospital number */}
      </Grid>

      <Grid container spacing={2}>
        {/* Gender */}
        <Grid item xs={12} md={4} marginTop={4}>
          <Box className="input-label">
            <Typography variant="body2">Gender</Typography>{" "}
            <Typography className="input-label-require" variant="body2">
              &nbsp;*
            </Typography>
            <Typography variant="body2">&nbsp;:</Typography>
          </Box>
          <DropdownSelection
            options={GenderOptionsConstant}
            selected={PatientStore.patient?.gender}
            optionKey="label"
            valueKey="value"
            placeholder="Gender"
            isError={PatientStore.patientIsInvalid?.gender}
            isShowErrorMessage={PatientStore.patientIsInvalid?.gender}
            errorMessage="Gender is invalid"
            isDisabled={PatientStore.isReadOnly}
            isReadOnly={PatientStore.isReadOnly}
            onSelected={(value: any) => onInputChange("gender", value)}
          />
        </Grid>
        {/* /.Gender */}

        {/* Title name */}
        <Grid item xs={12} md={4} marginTop={4}>
          <Box className="input-label">
            <Typography variant="body2">Title</Typography>{" "}
            <Typography className="input-label-require" variant="body2">
              &nbsp;*
            </Typography>
            <Typography variant="body2">&nbsp;:</Typography>
          </Box>
          <DropdownSelection
            options={titleNames}
            selected={PatientStore.patient?.titleName}
            optionKey="name"
            valueKey="id"
            placeholder="Title"
            isError={PatientStore.patientIsInvalid?.titleName}
            isShowErrorMessage={PatientStore.patientIsInvalid?.titleName}
            errorMessage="Title is invalid"
            isDisabled={PatientStore.isReadOnly}
            isReadOnly={PatientStore.isReadOnly}
            onSelected={(value: any) => onInputChange("titleName", value)}
          />
        </Grid>
        {/* /.Title name */}

        {/* Title name misc */}
        <Grid item xs={12} md={4} marginTop={4}>
          <InputTextField
            id="titleNameMisc"
            label="Specify"
            placeholder="Specify"
            type="text"
            value={PatientStore.patient?.titleNameMisc || ""}
            isDisabled={
              PatientStore.isReadOnly ||
              PatientStore.patient?.titleName?.id !== TitleNameOtherIdConstant
            }
            isReadOnly={PatientStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("titleNameMisc", value)
            }
          />
        </Grid>
        {/* /.Title name misc */}
      </Grid>

      <Grid container spacing={2}>
        {/* First name */}
        <Grid item xs={12} md={4} marginTop={4}>
          <InputTextField
            id="firstName"
            label="First name"
            placeholder="First name"
            type="name"
            value={PatientStore.patient?.firstName}
            errorMessage="First name is invalid"
            isShowErrorMessage={PatientStore.patientIsInvalid?.firstName}
            isError={PatientStore.patientIsInvalid?.firstName}
            isDisabled={PatientStore.isReadOnly}
            isReadOnly={PatientStore.isReadOnly}
            isRequire={true}
            handleOnChange={(value: string) =>
              onInputChange("firstName", value)
            }
          />
        </Grid>
        {/* /.First name */}

        {/* Last name */}
        <Grid item xs={12} md={4} marginTop={4}>
          <InputTextField
            id="lastName"
            label="Last name"
            placeholder="Last name"
            type="name"
            value={PatientStore.patient?.lastName}
            errorMessage="Last name is invalid"
            isShowErrorMessage={PatientStore.patientIsInvalid?.lastName}
            isError={PatientStore.patientIsInvalid?.lastName}
            isDisabled={PatientStore.isReadOnly}
            isReadOnly={PatientStore.isReadOnly}
            isRequire={true}
            handleOnChange={(value: string) => onInputChange("lastName", value)}
          />
        </Grid>
        {/* /.Last name */}

        {/* Middle name */}
        <Grid item xs={12} md={4} marginTop={4}>
          <InputTextField
            id="middleName"
            label="Middle name"
            placeholder="Middle name"
            type="name"
            value={PatientStore.patient?.middleName}
            isDisabled={PatientStore.isReadOnly}
            isReadOnly={PatientStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("middleName", value)
            }
          />
        </Grid>
        {/* /.Middle name */}
      </Grid>

      <Grid container spacing={2}>
        {/* Birth date */}
        <Grid item xs={12} md={4} marginTop={4}>
          <DatePicker
            label="Birth date"
            isRequire={true}
            dateSelected={PatientStore.patient?.birthDate}
            isError={PatientStore.patientIsInvalid?.birthDate}
            errorMessage="Birth date is invalid"
            isShowErrorMessage={PatientStore.patientIsInvalid.birthDate}
            isDisabled={PatientStore.isReadOnly}
            isReadOnly={PatientStore.isReadOnly}
            onDateSelected={(date) => onInputChange("birthDate", date)}
          />
        </Grid>
        {/* /.Birth date */}

        {/* Date of admission */}
        <Grid item xs={12} md={4} marginTop={4}>
          <DatePicker
            label="Date of admission"
            isRequire={true}
            dateSelected={PatientStore.patient?.admissionDate}
            isError={PatientStore.patientIsInvalid?.admissionDate}
            errorMessage="Date of admission is invalid"
            isShowErrorMessage={PatientStore.patientIsInvalid.admissionDate}
            isDisabled={PatientStore.isReadOnly}
            isReadOnly={PatientStore.isReadOnly}
            onDateSelected={(date) => onInputChange("admissionDate", date)}
          />
        </Grid>
        {/* /.Date of admission */}

        {/* Reimbursement */}
        <Grid item xs={12} md={4} marginTop={4}>
          <Box className="input-label">
            <Typography variant="body2">
              Reimbursement (สิทธิการรักษา)
            </Typography>
            <Typography variant="body2">&nbsp;:</Typography>
          </Box>
          <DropdownSelection
            options={reimbursements}
            selected={PatientStore.patient?.reimbursement}
            optionKey="name"
            valueKey="id"
            placeholder="Reimbursement (สิทธิการรักษา)"
            errorMessage="Reimbursement is invalid"
            isDisabled={PatientStore.isReadOnly}
            isReadOnly={PatientStore.isReadOnly}
            onSelected={(value: any) => onInputChange("reimbursement", value)}
          />
        </Grid>
        {/* /.Reimbursement */}
      </Grid>

      <Box marginTop={4}>
        <Box className="input-label">
          <Typography variant="body2">Remark</Typography>{" "}
          <Typography variant="body2">&nbsp;:</Typography>
        </Box>
        <InputTextArea
          placeholder="Remark"
          value={PatientStore.patient?.remark}
          isDisabled={PatientStore.isReadOnly}
          isReadOnly={PatientStore.isReadOnly}
          handleOnChange={(value: string) => onInputChange("remark", value)}
        />
      </Box>
    </>
  );
});

export default Form;
