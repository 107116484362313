import Axios from "../../plugins/Axios";

const CreatePostoperative = async (patientId: string, body: any) => {
  try {
    const { data } = await Axios.post(
      `postoperative/patients/${patientId}`,
      body
    );
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetSearchPostoperative = async (query: Object) => {
  try {
    const { data } = await Axios.get("/postoperative/search", {
      params: { ...query },
    });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetCountPostoperativeBySearch = async (query: Object) => {
  try {
    const { data } = await Axios.get("postoperative/count", {
      params: { ...query },
    });
    return {
      success: true,
      data: data?.data?.count || 0,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetPostoperativeById = async (id: string) => {
  try {
    const { data } = await Axios.get(`/postoperative/${id}`);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const UpdatePostoperativeById = async (id: string, body: any) => {
  try {
    const { data } = await Axios.patch(`/postoperative/${id}`, body);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const DeletePostoperative = async (id: string) => {
  try {
    const { data } = await Axios.delete(`/postoperative/${id}`);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const ExportPostoperativeByIdAsPdf = async (id: string) => {
  try {
    const { data } = await Axios.get(`/postoperative/${id}/pdf`, {
      responseType: "blob",
    });
    return {
      success: true,
      data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const ExportPostoperativeByIdAsImage = async (id: string) => {
  try {
    const { data } = await Axios.get(`/postoperative/${id}/image`, {
      responseType: "blob",
    });
    return {
      success: true,
      data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const DownloadZipImagePostoperativeById = async (id: string, ids: string[]) => {
  try {
    const { data } = await Axios.post(
      `/postoperative/${id}/image/zip`,
      { ids },
      {
        responseType: "blob",
      }
    );
    return {
      success: true,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

export {
  CreatePostoperative,
  GetSearchPostoperative,
  GetCountPostoperativeBySearch,
  GetPostoperativeById,
  UpdatePostoperativeById,
  DeletePostoperative,
  ExportPostoperativeByIdAsPdf,
  ExportPostoperativeByIdAsImage,
  DownloadZipImagePostoperativeById,
};
