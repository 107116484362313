import React, { useState } from "react";
import { observer } from "mobx-react";
import { Box, Stack, Grid, Typography } from "@mui/material";
import {
  FileDownload as FileDownloadIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useStores } from "../../../../stores";
import { Colors as ColorsConstant } from "../../../../constants/themes/Colors";
import {
  Base64Upload as Base64UploadService,
  DeleteFile as DeleteFileService,
  UpdateLabelFile as UpdateLabelFileService,
  UpdateRotateFile as UpdateRotateFileService,
} from "../../../../services/Storage";
import { DownloadZipImagePostoperativeById as DownloadZipImagePostoperativeByIdService } from "../../../../services/colorectals/Postoperative";
import { PostoperativeComplication as PostoperativeComplicationModel } from "../../../../models/colorectoralSurgeries/postoperatives/Postoperative";
import {
  DownloadFileFromUrl as DownloadFileFromUrlUtil,
  ConvertFileToBase64 as ConvertFileToBase64Util,
  OpenFileFromObjectUrlInNewTab as OpenFileFromObjectUrlInNewTabUtil,
} from "../../../../utils/File";
import DragAndDropImageUpload from "../../../uploads/DragAndDropImage";
import CheckBoxSelection from "../../../inputs/CheckboxSelection";
import InputTextField from "../../../inputs/TextField";
import ModalImageRotateEditor from "../../../modals/ImageRotateEditor";
import LoadingWholeScreen from "../../../loading/WholeScreen";
import ModalDelete from "../../../../components/modals/Delete";

interface Props {}

const FormImageUploadTissuePathology = observer((props: Props) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { PostoperativeStore } = useStores();
  const [isModalImageRotateEditorOpen, setIsModalImageRotateEditorOpen] =
    useState(false);
  const [imageSelected, setImageSelected] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [deleteModalTitle, setDeleteModalTitle] = useState("");
  const [deleteModalDescription, setDeleteModalDescription] = useState("");
  const [fileSelected, setFileSelected] = useState(null);
  const { AuthenticationStore } = useStores();

  const handleFileUpload = async (files: any) => {
    setIsLoading(true);
    const formPostoperativeComplication: PostoperativeComplicationModel =
      JSON.parse(JSON.stringify(PostoperativeStore.postoperativeComplication));
    for (let i = 0; i < files.length; i++) {
      await ConvertFileToBase64Util(files[i], async (base64: string) => {
        const splitBase64 = base64.split(",");
        const response = await Base64UploadService(splitBase64[1], {
          fieldname: "file",
          originalname: files[i].name,
          encoding: "7bit",
          mimetype: files[i].type,
          size: files[i].size,
        });
        if (response.success) {
          if (formPostoperativeComplication.files)
            formPostoperativeComplication.files.push({
              ...response.data,
              label: "",
              checked: false,
            });
          else {
            formPostoperativeComplication.files = [
              {
                ...response.data,
                label: "",
                checked: false,
              },
            ];
          }
        }
        PostoperativeStore.onSetPostoperativeComplication({
          ...formPostoperativeComplication,
        });
      });
    }
    setIsLoading(false);
  };
  const onImageSelected = (index: number, value: boolean) => {
    const form: PostoperativeComplicationModel =
      PostoperativeStore.postoperativeComplication;
    if (form.files)
      form.files[index] = { ...form.files[index], checked: value };
    PostoperativeStore.onSetPostoperativeComplication({ ...form });
  };
  const onInputLabelImageChange = (index: number, value: string) => {
    const form: PostoperativeComplicationModel =
      PostoperativeStore.postoperativeComplication;
    if (form.files) form.files[index] = { ...form.files[index], label: value };
    PostoperativeStore.onSetPostoperativeComplication({ ...form });
  };
  const handleOpenModalImageRotateEditor = (image: any) => {
    setImageSelected(image);
    setIsModalImageRotateEditorOpen(true);
  };
  const handleCloseModalImageRotateEditor = () => {
    setIsModalImageRotateEditorOpen(false);
    setImageSelected(null);
  };
  const handleSaveImageRotated = async (degree: number) => {
    setIsLoading(true);
    setIsModalImageRotateEditorOpen(false);
    const form: any = PostoperativeStore.postoperativeComplication;
    const index: number = form.files?.findIndex(
      (item: any) => item.id === imageSelected?.id
    );
    const tmpFileData = form.files[index];
    const response = await UpdateRotateFileService(imageSelected?.id, {
      degree,
    });
    if (response.success && index >= 0) {
      handleCloseModalImageRotateEditor();
      form.files[index] = {
        ...response.data,
        label: tmpFileData.label,
        checked: tmpFileData?.checked,
      };
    } else {
      form.files[index] = {
        ...tmpFileData,
      };
    }
    PostoperativeStore.onSetPostoperativeComplication({ ...form });
    setIsLoading(false);
  };
  const handleDeleteImage = async (image: any) => {
    setIsModalDeleteOpen(false);
    setIsLoading(true);
    const response = await DeleteFileService(image?.id || "");
    if (response.success) {
      const form: any = PostoperativeStore.postoperativeComplication;
      form.files = form.files?.filter((file: any) => file.id !== image.id);
      PostoperativeStore.onSetPostoperativeComplication({ ...form });
    }
    setIsLoading(false);
    handleCloseModalDelete();
  };
  const handleDeleteMultipleImage = async () => {
    setIsModalDeleteOpen(false);
    const filterImagesSelected =
      PostoperativeStore.postoperativeComplication?.files?.filter(
        (file) => file.checked
      );
    if (filterImagesSelected?.length) {
      for (const image of filterImagesSelected) {
        await handleDeleteImage(image);
      }
    }
    handleCloseModalDelete();
  };
  const onUpdateImageLabel = async (index: number) => {
    const form: any = PostoperativeStore.postoperativeComplication;
    const { id, label } = form.files[index];
    UpdateLabelFileService(id || "", { label });
  };
  const onDownloadImage = (file: any) => {
    DownloadFileFromUrlUtil(file.name, AuthenticationStore.user?.tenant?.id);
  };
  const onHandleCheckAllImage = (value: boolean) => {
    const form: any = PostoperativeStore.postoperativeComplication;
    form.files = form.files?.map((file: any) => {
      return { ...file, checked: value };
    });
    PostoperativeStore.onSetPostoperativeComplication({ ...form });
  };
  const isCheckAll = () => {
    return Object.values(
      PostoperativeStore.postoperativeComplication?.files || []
    ).every((v) => v.checked === true);
  };
  const handleOpenModalDelete = (
    title: string,
    description: string,
    file: any
  ) => {
    setDeleteModalTitle(title);
    setDeleteModalDescription(description);
    setFileSelected(file);
    setIsModalDeleteOpen(true);
  };
  const handleCloseModalDelete = () => {
    setDeleteModalTitle("");
    setDeleteModalDescription("");
    setIsModalDeleteOpen(false);
  };
  const handleConfirmDeleteImage = () => {
    if (deleteModalTitle === "Delete Image Or Video")
      handleDeleteImage(fileSelected);
    else if (deleteModalTitle === "Delete All Image Or Video")
      handleDeleteMultipleImage();
  };
  const onDownloadImageAllSelected = async () => {
    const filterFileSelected =
      PostoperativeStore.postoperativeComplication.files?.filter(
        (file) => file.checked
      );
    const storagesId: string[] =
      filterFileSelected?.map((file) => {
        return file.id || "";
      }) || [];
    if (!PostoperativeStore.postoperative.id || !storagesId.length) return;
    setIsLoading(true);
    const response = await DownloadZipImagePostoperativeByIdService(
      PostoperativeStore.postoperative.id || "",
      storagesId
    );
    response.success && OpenFileFromObjectUrlInNewTabUtil(response.data);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <LoadingWholeScreen isShowDescription={true} />}
      <Box className="input-label">
        <Typography variant="body2">
          Images and videos of postoperative tissue pathology :
        </Typography>
      </Box>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        display={
          !PostoperativeStore.postoperativeComplication?.files?.length
            ? "none"
            : "flex"
        }
        marginTop={1}
      >
        <Box color={ColorsConstant.neutral}>
          <CheckBoxSelection
            label="Select all"
            isChecked={isCheckAll()}
            isDisabled={false}
            onChange={(value: boolean) => {
              onHandleCheckAllImage(value);
            }}
          />
        </Box>
        <Box display={"flex"} gap={2}>
          <Box
            className="cursor-pointer"
            display={PostoperativeStore.postoperative.id ? "flex" : "none"}
            alignItems={"center"}
            onClick={onDownloadImageAllSelected}
          >
            <FileDownloadIcon
              sx={{ color: ColorsConstant.primary, fontSize: 24 }}
            />
            <Typography
              variant="body2"
              marginLeft={0.5}
              color={ColorsConstant.primary}
            >
              Download
            </Typography>
          </Box>
          <Box
            className="cursor-pointer"
            display={!PostoperativeStore.isReadOnly ? "flex" : "none"}
            alignItems="center"
            color={ColorsConstant.error}
            onClick={() =>
              handleOpenModalDelete(
                "Delete All Image Or Video",
                "Are you sure you want to delete all image and video.",
                null
              )
            }
          >
            <DeleteIcon sx={{ fontSize: 24 }} />
            <Typography variant="body2" marginLeft={0.5}>
              Delete
            </Typography>
          </Box>
        </Box>
      </Stack>
      <Grid container spacing={2}>
        {(PostoperativeStore.postoperativeComplication?.files?.length &&
          PostoperativeStore.postoperativeComplication?.files?.map(
            (file: any, index: number) => {
              return (
                <Grid item xs={12} md={3} marginTop={1} key={index}>
                  <Box
                    border="1px solid"
                    borderColor={ColorsConstant.border}
                    borderRadius="6px"
                    height="100%"
                  >
                    <Box padding={2}>
                      <Stack direction="row" justifyContent="space-between">
                        <Box display={"flex"}>
                          <CheckBoxSelection
                            label=""
                            isChecked={file.checked}
                            isDisabled={false}
                            onChange={(value: boolean) => {
                              onImageSelected(index, value);
                            }}
                          />
                        </Box>
                        <Box
                          className="cursor-pointer"
                          marginLeft="auto"
                          onClick={() => onDownloadImage(file)}
                        >
                          <FileDownloadIcon
                            sx={{ color: ColorsConstant.primary, fontSize: 24 }}
                          />
                        </Box>
                      </Stack>
                      <Box marginTop={1} marginBottom={0.5} position="relative">
                        {file.meta?.mimetype?.startsWith("image/") && (
                          <Box
                            className="cursor-pointer"
                            onClick={() =>
                              handleOpenModalImageRotateEditor(file)
                            }
                          >
                            <img
                              className="object-fit-cover aspect-ratio-16-9"
                              src={`${apiUrl}/storages/${
                                file.name
                              }?thumbnail=true&t=${new Date().getTime()}&tenant=${
                                AuthenticationStore.user?.tenant?.id
                              }`}
                              alt="img-operative-note"
                              style={{ borderRadius: "6px" }}
                              loading="lazy"
                            />
                          </Box>
                        )}
                        {file.meta?.mimetype?.startsWith("video/") && (
                          <Box>
                            <video
                              className="aspect-ratio-16-9 object-fit-contain"
                              controls
                              controlsList="nodownload"
                              style={{ borderRadius: 6 }}
                            >
                              <source
                                src={`${apiUrl}/storages/${file.name}?tenant=${AuthenticationStore.user?.tenant?.id}`}
                              />
                            </video>
                          </Box>
                        )}
                        <Box
                          style={{
                            backgroundColor: ColorsConstant.white,
                            borderRadius: "100%",
                          }}
                          width={22}
                          height={22}
                          display={
                            PostoperativeStore.isReadOnly ? "none" : "flex"
                          }
                          alignItems="center"
                          justifyContent="center"
                          position="absolute"
                          right={-10}
                          top={-10}
                          className="cursor-pointer"
                        />
                        <Box
                          position="absolute"
                          right={-10}
                          top={-10}
                          className="cursor-pointer"
                          display={
                            PostoperativeStore.isReadOnly ? "none" : "flex"
                          }
                          onClick={() =>
                            handleOpenModalDelete(
                              "Delete Image Or Video",
                              "Are you sure you want to delete image or video.",
                              file
                            )
                          }
                        >
                          <CancelIcon
                            sx={{
                              color: ColorsConstant.error,
                              fontSize: 24,
                            }}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <InputTextField
                          id="imageLabel"
                          label=""
                          placeholder="Label"
                          type="text"
                          value={file.label}
                          isDisabled={PostoperativeStore.isReadOnly}
                          isReadOnly={PostoperativeStore.isReadOnly}
                          isRequire={false}
                          handleOnChange={(value: string) =>
                            onInputLabelImageChange(index, value)
                          }
                          handleOnBlur={() => onUpdateImageLabel(index)}
                          handleOnClear={() => onUpdateImageLabel(index)}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              );
            }
          )) ||
          null}
        <Grid
          display={PostoperativeStore.isReadOnly ? "none" : ""}
          item
          xs={12}
          md={3}
          marginTop={1}
        >
          <DragAndDropImageUpload
            id="operativeNoteUploadImage"
            titleModal="Upload operative note image"
            onChange={handleFileUpload}
          />
        </Grid>
      </Grid>
      {PostoperativeStore.isReadOnly &&
        !PostoperativeStore.postoperativeComplication?.files?.length && (
          <Box paddingY={3} textAlign="center">
            <Typography variant="subtitle1">No image and video</Typography>
          </Box>
        )}
      <ModalImageRotateEditor
        isOpen={isModalImageRotateEditorOpen}
        img={
          (imageSelected?.name &&
            `${apiUrl}/storages/${
              imageSelected?.name
            }?t=${new Date().getTime()}&tenant=${
              AuthenticationStore.user?.tenant?.id
            }`) ||
          ""
        }
        isDisable={PostoperativeStore.isReadOnly}
        onClose={handleCloseModalImageRotateEditor}
        onSubmitImageRotated={(degree: number) =>
          handleSaveImageRotated(degree)
        }
      />
      <ModalDelete
        isOpen={isModalDeleteOpen}
        title={deleteModalTitle}
        description={deleteModalDescription}
        information=""
        onClose={handleCloseModalDelete}
        onDelete={handleConfirmDeleteImage}
      />
    </>
  );
});

export default FormImageUploadTissuePathology;
