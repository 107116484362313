import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import { useStores } from "../../../stores";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { DropdownTypes as DropdownTypesConstant } from "../../../constants/Dropdown";
import { Dropdown as DropdownModel } from "../../../models/Dropdown";
import { GetDropdownByType as GetDropdownByTypeService } from "../../../services/Dropdown";
import CheckboxGroupSelection from "../../inputs/CheckboxGroupSelection";
import InputNumberField from "../../inputs/NumberField";
import InputTextField from "../../inputs/TextField";
import DropdownSelection from "../../dropdowns/Selection";

interface Props {}

const FormAnastomosisSection = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();
  const [circularStaples, setCircularStaples] = useState<DropdownModel[]>([]);
  const [repairBys, setRepairBys] = useState<DropdownModel[]>([]);

  useEffect(() => {
    if (!OperativeNoteStore.isReadOnly) {
      getDropdownCircularStaples();
      getDropdownRepairBys();
    }
  }, [OperativeNoteStore.isReadOnly]);

  const getDropdownCircularStaples = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.operativeNoteCircularStaple
    );
    response.success && setCircularStaples([...response.data]);
  };
  const getDropdownRepairBys = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.operativeNoteRepairBy
    );
    response.success && setRepairBys([...response.data]);
  };
  const onInputChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.anastomosisSection };
    form[key] = value;
    OperativeNoteStore.onSetAnastomosisSection({ ...form });
  };
  const onInputAnastomoticTypesChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.anastomoticTypes];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetAnastomoticTypes([...forms]);
  };
  const onInputAnastomoticTechniquesChange = (
    index: number,
    value: boolean
  ) => {
    const forms: any = [...OperativeNoteStore.anastomoticTechniques];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetAnastomoticTechniques([...forms]);
  };
  const onInputAnastomosisChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.anastomosis];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetAnastomosis([...forms]);
  };
  const onInputDistalRectalWashoutsChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.distalRectalWashouts];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetDistalRectalWashouts([...forms]);
  };
  const onInputAirLeakTestsChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.airLeakTests];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetAirLeakTests([...forms]);
  };

  return (
    <>
      <Box
        id={ColorectalSurgeryOperativeNoteFormConstant.anastomosisSection}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Anastomosis section</Typography>
      </Box>
      <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
        {/* Anastomotic type */}
        <Box
          marginTop="0 !important"
          paddingTop={3}
          width={{ xs: "100%", md: "20%" }}
        >
          <Box className="input-label">
            <Typography variant="body2">Anastomotic type :</Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.anastomoticTypes}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={12}
            gridColumnLg={12}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputAnastomoticTypesChange(index, value)
            }
          />
        </Box>
        {/* /.Anastomotic type */}

        {/* Technique */}
        <Box
          marginTop="0 !important"
          paddingTop={3}
          width={{ xs: "100%", md: "20%" }}
        >
          <Box className="input-label">
            <Typography variant="body2">Technique :</Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.anastomoticTechniques}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={12}
            gridColumnLg={12}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputAnastomoticTechniquesChange(index, value)
            }
          />
        </Box>
        {/* /.Technique */}

        {/* Linear staple (pieces) */}
        <Box
          marginTop="0 !important"
          paddingTop={3}
          width={{ xs: "100%", md: "20%" }}
        >
          <InputNumberField
            id="staplerPiece"
            label="Linear staple (pieces)"
            placeholder="Linear staple (pieces)"
            isRequire={false}
            isReadOnly={OperativeNoteStore.isReadOnly}
            value={OperativeNoteStore.anastomosisSection?.linearStaple}
            handleOnChange={(value: number | string) => {
              onInputChange("linearStaple", value);
            }}
          />
        </Box>
        {/* /.Linear staple (pieces) */}

        {/* Circular staple (size) */}
        <Box
          marginTop="0 !important"
          paddingTop={3}
          width={{ xs: "100%", md: "20%" }}
        >
          <Box className="input-label">
            <Typography variant="body2">Circular staple (size) :</Typography>
          </Box>
          <DropdownSelection
            options={circularStaples}
            selected={OperativeNoteStore.anastomosisSection?.circularStaple}
            optionKey="value"
            valueKey="id"
            placeholder="Circular staple (size)"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) => onInputChange("circularStaple", value)}
          />
        </Box>
        {/* /.Circular staple (size) */}

        {/* Anastomosis */}
        <Box
          marginTop="0 !important"
          paddingTop={3}
          width={{ xs: "100%", md: "20%" }}
        >
          <Box className="input-label">
            <Typography variant="body2">Anastomosis :</Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.anastomosis}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={12}
            gridColumnLg={12}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputAnastomosisChange(index, value)
            }
          />
        </Box>
        {/* /.Anastomosis */}
      </Stack>

      <Grid container spacing={2} marginTop={1}>
        {/* Distal rectal washout */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Distal rectal washout :</Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.distalRectalWashouts}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={3}
            gridColumnLg={3}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputDistalRectalWashoutsChange(index, value)
            }
          />
        </Grid>
        {/* /.Distal rectal washout */}

        {/* Air-leak test */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Air-leak test :</Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.airLeakTests}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={3}
            gridColumnLg={3}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputAirLeakTestsChange(index, value)
            }
          />
        </Grid>
        {/* /.Air-leak test */}
      </Grid>

      <Grid container spacing={2} marginTop={1}>
        {/* Repair by */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Repair by :</Typography>
          </Box>
          <DropdownSelection
            options={repairBys}
            selected={OperativeNoteStore.anastomosisSection?.repairBy}
            optionKey="value"
            valueKey="id"
            placeholder="Repair by"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) => onInputChange("repairBy", value)}
          />
        </Grid>
        {/* /.Repair by */}

        {/* Repair detail */}
        <Grid item xs={12} md={6} marginTop={1}>
          <InputTextField
            id="repairByDetail"
            label="Repair detail, if positive"
            placeholder="Repair detail, if positive"
            type="text"
            value={OperativeNoteStore.anastomosisSection?.repairByDetail}
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("repairByDetail", value)
            }
          />
        </Grid>
        {/* /.Repair detail */}
      </Grid>
    </>
  );
});

export default FormAnastomosisSection;
