import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { useStores } from "../../../stores";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { DropdownTypes as DropdownTypesConstant } from "../../../constants/Dropdown";
import {
  RectalTransactionKey as RectalTransactionKeyConstant,
  StaplerEnum as StaplerEnumConstant,
} from "../../../constants/colorectalSurgery/operativeNotes/Ligation";
import { Dropdown as DropdownModel } from "../../../models/Dropdown";
import { GetDropdownByType as GetDropdownByTypeService } from "../../../services/Dropdown";
import RadioSelection from "../../inputs/RadioSelection";
import CheckboxGroupSelection from "../../inputs/CheckboxGroupSelection";
import CheckboxSelection from "../../inputs/CheckboxSelection";
import InputNumberField from "../../inputs/NumberField";
import DropdownSelection from "../../dropdowns/Selection";
import LigationStaticTable from "./LigationStaticTable";

interface Props {}

const FormLigation = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();
  const [imaLigations, setImaLigations] = useState<DropdownModel[]>([]);
  const [imvLigations, setImvLigations] = useState<DropdownModel[]>([]);

  useEffect(() => {
    if (!OperativeNoteStore.isReadOnly) {
      getDropdownImaLigations();
      getDropdownImvLigations();
    }
  }, [OperativeNoteStore.isReadOnly]);

  const getDropdownImaLigations = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.operativeNoteImaLigation
    );
    response.success && setImaLigations([...response.data]);
  };
  const getDropdownImvLigations = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.operativeNoteImvLigation
    );
    response.success && setImvLigations([...response.data]);
  };
  const onLigationIsInvalidChange = (key: string, value: boolean) => {
    const formIsInvalid: any = { ...OperativeNoteStore.ligationIsInvalid };
    formIsInvalid[key] && (formIsInvalid[key] = value);
    OperativeNoteStore.onSetLigationIsInvalid({ ...formIsInvalid });
  };
  const onInputLigationChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.ligation };
    form[key] = value;
    OperativeNoteStore.onSetLigation({ ...form });
    onLigationIsInvalidChange(key, false);
  };
  const onInputDetailOfVascularLigations = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.detailOfVascularLigations];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetDetailOfVascularLigations([...forms]);
  };
  const onInputRectalTransactionChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.rectalTransactions];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetRectalTransactions([...forms]);
    if (forms[index]?.key === RectalTransactionKeyConstant.stapler && !value) {
      onInputLigationChange("staplerValue", "");
      onInputLigationChange("staplerPiece", 0);
    } else if (forms[index]?.key === RectalTransactionKeyConstant.isr)
      OperativeNoteStore.onClearIsr();
  };
  const onInputIsrChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.isr];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetIsr([...forms]);
    onLigationIsInvalidChange("isr", false);
  };
  const onInputLevelOfSphincterExcisionChange = (
    index: number,
    value: boolean
  ) => {
    const forms: any = [...OperativeNoteStore.levelOfSphincterExcision];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetLevelOfSphincterExcision([...forms]);
    onLigationIsInvalidChange("levelOfSphincterExcision", false);
  };
  const rectalTransactionSubInput = (key: string) => {
    switch (key) {
      case RectalTransactionKeyConstant.stapler:
        return (
          <>
            <Box
              className="input-label"
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="body2">Stapler</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <RadioSelection
                  value={StaplerEnumConstant.johnson}
                  selectedValue={
                    OperativeNoteStore.ligation?.staplerValue || ""
                  }
                  label="Johnson"
                  isDisabled={OperativeNoteStore.isReadOnly}
                  onChange={(value: any) =>
                    onInputLigationChange("staplerValue", value)
                  }
                />
                <Box marginTop={2}>
                  <RadioSelection
                    value={StaplerEnumConstant.convidien}
                    selectedValue={
                      OperativeNoteStore.ligation?.staplerValue || ""
                    }
                    label="Covidien"
                    isDisabled={OperativeNoteStore.isReadOnly}
                    onChange={(value: any) =>
                      onInputLigationChange("staplerValue", value)
                    }
                  />
                </Box>
                <Box marginTop={2}>
                  <RadioSelection
                    value={StaplerEnumConstant.sureform}
                    selectedValue={
                      OperativeNoteStore.ligation?.staplerValue || ""
                    }
                    label="Sureform"
                    isDisabled={OperativeNoteStore.isReadOnly}
                    onChange={(value: any) =>
                      onInputLigationChange("staplerValue", value)
                    }
                  />
                </Box>
                {OperativeNoteStore.ligationIsInvalid?.staplerValue &&
                  displayErrorMessage("Select a stapler")}
              </Grid>
              <Grid item xs={12} md={6}>
                <InputNumberField
                  id="staplerPiece"
                  label="Piece"
                  placeholder="Piece"
                  isRequire={true}
                  isReadOnly={OperativeNoteStore.isReadOnly}
                  value={OperativeNoteStore.ligation?.staplerPiece}
                  isError={OperativeNoteStore.ligationIsInvalid?.staplerPiece}
                  isShowErrorMessage={
                    OperativeNoteStore.ligationIsInvalid?.staplerPiece
                  }
                  errorIconSize={16}
                  errorMessage="Piece is invalid"
                  handleOnChange={(value: number | string) => {
                    onInputLigationChange("staplerPiece", value);
                  }}
                />
              </Grid>
            </Grid>
          </>
        );
      case RectalTransactionKeyConstant.isr:
        return (
          <>
            <Box
              className="input-label"
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
              paddingBottom={1}
            >
              <Typography variant="body2">ISR</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.isr}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={12}
              gridColumnLg={12}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputIsrChange(index, value)
              }
            />
            {OperativeNoteStore.ligationIsInvalid?.isr &&
              displayErrorMessage("Select the isr")}
            <Box
              className="input-label"
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
              paddingBottom={1}
              marginTop={1}
            >
              <Typography variant="body2">
                Level of sphincter excision
              </Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.levelOfSphincterExcision}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={12}
              gridColumnLg={12}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputLevelOfSphincterExcisionChange(index, value)
              }
            />
            {OperativeNoteStore.ligationIsInvalid?.levelOfSphincterExcision &&
              displayErrorMessage("Select the level of sphincter excision")}
          </>
        );
      default:
        break;
    }
  };
  const displayErrorMessage = (message: string) => {
    return (
      <Stack
        className="error-message"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        marginTop={0.5}
      >
        <ErrorIcon sx={{ fontSize: 16 }} />
        <Typography variant="subtitle2">{message}</Typography>
      </Stack>
    );
  };

  return (
    <>
      <Box
        id={ColorectalSurgeryOperativeNoteFormConstant.ligation}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Ligation</Typography>
      </Box>
      <Grid container spacing={2} marginTop={1}>
        {/* Central vascular ligation */}
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">Central vascular ligation :</Typography>
          </Box>
          <Box display="flex" marginTop={1}>
            <RadioSelection
              value="true"
              selectedValue={
                OperativeNoteStore.ligation?.centralVascularLigation || ""
              }
              label="Yes"
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(value: any) =>
                onInputLigationChange("centralVascularLigation", value)
              }
            />
            <RadioSelection
              value="false"
              selectedValue={
                OperativeNoteStore.ligation?.centralVascularLigation || ""
              }
              label="No"
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(value: any) =>
                onInputLigationChange("centralVascularLigation", value)
              }
            />
          </Box>
        </Grid>
        {/* /.Central vascular ligation */}

        {/* Detail of Vascular ligation */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">
              Detail of Vascular ligation :
            </Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.detailOfVascularLigations}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={6}
            gridColumnLg={6}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputDetailOfVascularLigations(index, value)
            }
          />
        </Grid>
        {/* /.Detail of Vascular ligation */}
      </Grid>
      <Grid container spacing={2} marginTop={1}>
        {/* IMA ligation */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">IMA ligation :</Typography>
          </Box>
          <DropdownSelection
            options={imaLigations}
            selected={OperativeNoteStore.ligation?.imaLigation}
            optionKey="value"
            valueKey="id"
            placeholder="IMA ligation"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) =>
              onInputLigationChange("imaLigation", value)
            }
          />
        </Grid>
        {/* /.IMA ligation */}

        {/* IMV ligation */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">IMV ligation :</Typography>
          </Box>
          <DropdownSelection
            options={imvLigations}
            selected={OperativeNoteStore.ligation?.imvLigation}
            optionKey="value"
            valueKey="id"
            placeholder="IMV ligation"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) =>
              onInputLigationChange("imvLigation", value)
            }
          />
        </Grid>
        {/* /.IMV ligation */}
      </Grid>
      {OperativeNoteStore.resection[1]?.value && (
        <>
          {/* Static table information */}
          <Box marginTop={3}>
            <LigationStaticTable />
          </Box>
          {/* /.Static table information */}
          {/* Rectal transection */}
          <Box className="input-label" marginTop={3} marginBottom={0}>
            <Typography variant="body2">Rectal transection :</Typography>
          </Box>
          {OperativeNoteStore.rectalTransactions?.map(
            (rectalTransaction, index) => {
              return (
                <Grid container spacing={0} key={index}>
                  <Grid item xs={12} md={6} marginTop={1}>
                    <CheckboxSelection
                      label={rectalTransaction.label}
                      isChecked={rectalTransaction.value}
                      isDisabled={OperativeNoteStore.isReadOnly}
                      onChange={(value: boolean) => {
                        onInputRectalTransactionChange(index, value);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    marginTop={1}
                    marginLeft={{ xs: 4, md: 0 }}
                  >
                    {rectalTransaction.value &&
                      rectalTransactionSubInput(rectalTransaction.key)}
                  </Grid>
                </Grid>
              );
            }
          )}
          {/* /.Rectal transection */}
        </>
      )}
    </>
  );
});

export default FormLigation;
