import React from "react";
import "../../assets/styles/Dropdown.scss";
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  Stack,
  Box,
  OutlinedInput,
} from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";

interface Props {
  options: any[];
  optionKey: string;
  valueKey: string;
  selected: any;
  placeholder: string;
  isError?: boolean;
  errorIconSize?: number;
  isShowErrorMessage?: boolean;
  errorMessage?: string;
  isDisabled: boolean;
  isReadOnly?: boolean;
  onSelected: (value: any) => void;
}

const Selection = (props: Props) => {
  const handleChange = (event: any) => {
    const value = event.target?.value;
    props.onSelected(JSON.parse(value));
  };

  return (
    <>
      <FormControl>
        {!props.isReadOnly ? (
          <Select
            displayEmpty
            value={
              props.selected[props.valueKey] && props.options.length
                ? JSON.stringify(props.selected)
                : ""
            }
            onChange={handleChange}
            input={
              <OutlinedInput className={`${props.isError && "input-error"}`} />
            }
            renderValue={() => {
              if (props.selected[props.valueKey] && props.options.length) {
                return (
                  <Typography
                    variant="body1"
                    color={ColorsConstant.black}
                    display="inline-block"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    width={"100%"}
                    className="dropdown-selected-text"
                  >
                    {props.selected[props.optionKey]}
                  </Typography>
                );
              } else {
                return (
                  <Typography
                    variant="body1"
                    color={ColorsConstant.placeholder}
                    display="block"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {props.placeholder}
                  </Typography>
                );
              }
            }}
            inputProps={{ "aria-label": "Without label" }}
            disabled={props.isDisabled}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 300, maxWidth: "calc(40% - 96px)" },
              },
            }}
          >
            {props.options?.map((option, index) => (
              <MenuItem
                key={index}
                value={JSON.stringify(option)}
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  width: "100%",
                  display: "block",
                }}
              >
                {option[props.optionKey]}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Box paddingTop={2} color={ColorsConstant.black}>
            <Typography variant="body1">
              {props?.selected[props.optionKey] || "-"}
            </Typography>
          </Box>
        )}
        {props.isError && props.isShowErrorMessage && (
          <Stack
            className="error-message"
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            marginTop={0.5}
          >
            <ErrorIcon sx={{ fontSize: props.errorIconSize || 24 }} />
            <Typography variant="subtitle2">{props.errorMessage}</Typography>
          </Stack>
        )}
      </FormControl>
    </>
  );
};

export default Selection;
