export const DropdownTypes = {
  anticoagulantDrugUsage: "ANTICOAGULANT_DRUG_USAGE",
  // preoperative
  preoperativeEcogPerformanceStatus: "PREOPERATIVE_ECOG_PERFORMANCE_STATUS",
  preoperativeAsaPhysicalStatus: "PREOPERATIVE_ASA_PHYSICAL_STATUS",
  preoperativeContinenceStatus: "PREOPERATIVE_CONTINENCE_STATUS",
  preoperativeLiverMetastasis: "PREOPERATIVE_LIVER_METASTASIS",
  preoperativeLungMetastasis: "PREOPERATIVE_LUNG_METASTASIS",
  preoperativeOtherMetastasis: "PREOPERATIVE_OTHER_METASTASIS",
  preoperativeClinicalStagingT: "PREOPERATIVE_CLINICAL_STAGING_T",
  preoperativeClinicalStagingN: "PREOPERATIVE_CLINICAL_STAGING_N",
  preoperativeClinicalStagingM: "PREOPERATIVE_CLINICAL_STAGING_M",
  // operative note
  operativeNoteEmergencyDetail: "OPNOTE_EMERGENCY_DETAIL",
  operativeNoteColorectalSurgery: "OPNOTE_COLORECTAL_SURGERY",
  operativeNoteSurgeonType: "OPNOTE_SG_TYPE",
  operativeNoteAbdominoperinealResection: "OPNOTE_ABDOMINOPERINEAL_RESECTION",
  operativeNoteTotalMesorectalExcision: "OPNOTE_TOTAL_MESORECTAL_EXCISION",
  operativeNoteDissectionPlane: "OPNOTE_DISSECTION_PLANE",
  operativeNotePelvicAutonomicNerve: "OPNOTE_PELVIC_AUTONOMIC_NERVE",
  operativeNoteImaLigation: "OPNOTE_IMA_LIGATION",
  operativeNoteImvLigation: "OPNOTE_IMV_LIGATION",
  operativeNoteGrosslyMargin: "OPNOTE_IGROSSLY_MARGIN",
  operativeNoteVaginalReconstructionDetail:
    "OPNOTE_VAGINAL_RECONSTRUCTION_DETAIL",
  operativeNotePalvicFloorDefectReconstruction:
    "OPNOTE_PELVIC_FLOOR_DEFECT_RECONSTRUCTION",
  operativeNoteClosureOfPerinealWound: "OPNOTE_CLOSURE_OF_PERINEAL_WOUND",
  operativeNoteCircularStaple: "OPNOTE_CIRCULAR_STAPLE",
  operativeNoteRepairBy: "OPNOTE_REPAIR_BY",
  // postoperative
  postoperativeStartLiquidDietDay: "POSTOPERATIVE_START_LIQUID_DIET_DAY",
};
