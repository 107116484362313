import React from "react";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
  FormGroup,
} from "@mui/material";
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  Square as SquareIcon,
} from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";

interface Props {
  options: any[];
  labelKey: string;
  valueKey: string;
  gridColumnXs: number;
  gridColumnMd: number;
  gridColumnLg: number;
  spacing?: number;
  isDisabled: boolean;
  iconSize?: number;
  onChange: any;
}

const CheckboxGroupSelection = (props: Props) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = event.target.checked;
    props.onChange(index, value);
  };

  return (
    <Grid container spacing={(props.spacing && props.spacing) || 2}>
      {props.options?.map((option, index) => {
        return (
          <Grid
            item
            key={index}
            xs={props.gridColumnXs}
            md={props.gridColumnMd}
            lg={props.gridColumnLg}
            alignSelf="center"
          >
            <FormGroup
              sx={{ display: "block" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, index)
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={option[props.valueKey]}
                    checkedIcon={
                      <Box
                        color={ColorsConstant.focused}
                        display="flex"
                        alignItems="center"
                      >
                        <CheckBoxIcon
                          sx={{
                            fontSize: props.iconSize || 20,
                            fill:
                              option.disabled || props.isDisabled
                                ? ColorsConstant.mainIcon
                                : ColorsConstant.focused,
                          }}
                        />
                      </Box>
                    }
                    icon={
                      option.disabled || props.isDisabled ? (
                        <Box
                          color={ColorsConstant.disable}
                          display="flex"
                          alignItems="center"
                        >
                          <SquareIcon
                            sx={{
                              fontSize: props.iconSize || 20,
                              fill: ColorsConstant.disable,
                            }}
                          />
                        </Box>
                      ) : (
                        <Box
                          color={ColorsConstant.focused}
                          display="flex"
                          alignItems="center"
                        >
                          <CheckBoxOutlineBlankIcon
                            sx={{
                              fontSize: props.iconSize || 20,
                              fill: ColorsConstant.mainIcon,
                            }}
                          />
                        </Box>
                      )
                    }
                    disabled={option.disabled || props.isDisabled}
                  />
                }
                label={option[props.labelKey]}
                disabled={option.disabled || props.isDisabled}
              />
            </FormGroup>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CheckboxGroupSelection;
