import React from "react";
import { observer } from "mobx-react";
import { Box, Typography, Stack, Grid } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import {
  AdenocarcinomaAndSquamousDifferentiationOptions as AdenocarcinomaAndSquamousDifferentiationOptionsConstant,
  TissuePathologyKeys as TissuePathologyKeysConstant,
} from "../../../constants/colorectalSurgery/Preoperative";
import { useStores } from "../../../stores";
import InputTextField from "../../inputs/TextField";
import RadioSelection from "../../inputs/RadioSelection";
import CheckboxSelection from "../../inputs/CheckboxSelection";
import RadioGroupSelectionInput from "../../inputs/RadioGroupSelection";

interface Props {}

const FormMalignant = observer((props: Props) => {
  const { PreoperativeStore } = useStores();

  const onInputChange = (key: string, value: any) => {
    const formObj: any = { ...PreoperativeStore.preoperative };
    const formIsInvalid: any = { ...PreoperativeStore.preoperativeIsInvalid };
    formObj[key] = value;
    formIsInvalid[key] && (formIsInvalid[key] = false);
    PreoperativeStore.onSetPreoperative(formObj);
    PreoperativeStore.onSetPreoperativeIsInvalid(formIsInvalid);
    if (key === "malignantCase" && (value === "" || value === "false")) {
      PreoperativeStore.onClearMalignantCaseForm();
    }
  };
  const onInputPreoperativeTissuePathologyChange = (
    value: boolean,
    index: number
  ) => {
    const forms = [...PreoperativeStore.preoperativeTissuePathologies];
    const formIsInvalid: any = { ...PreoperativeStore.preoperativeIsInvalid };
    forms[index] = { ...forms[index], value };
    formIsInvalid.preoperativeTissuePathology = false;
    switch (forms[index].key) {
      case TissuePathologyKeysConstant.adenocarcinoma:
        formIsInvalid.adenocarcinomaDifferentiation = false;
        onInputChange("adenocarcinomaDifferentiation", "");
        break;
      case TissuePathologyKeysConstant.squamousCellCa:
        formIsInvalid.squamousCellCaDifferentiation = false;
        onInputChange("squamousCellCaDifferentiation", "");
        break;
      case TissuePathologyKeysConstant.other:
        formIsInvalid.otherDetail = false;
        onInputChange("otherDetail", "");
        break;
      default:
        break;
    }
    PreoperativeStore.onSetPreoperativeTissuePathologies([...forms]);
    PreoperativeStore.onSetPreoperativeIsInvalid(formIsInvalid);
  };
  const preoperativeTissuePathologySubInput = (key: string) => {
    switch (key) {
      case "adenocarcinoma":
        return (
          <Box marginLeft={{ xs: 4, md: 0 }}>
            <Box className="input-label">
              <Typography variant="body2">
                Adenocarcinoma Differentiation
              </Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*
              </Typography>
              <Typography variant="body2">&nbsp;:</Typography>
            </Box>
            <RadioGroupSelectionInput
              options={AdenocarcinomaAndSquamousDifferentiationOptionsConstant}
              selectedValue={
                PreoperativeStore.preoperative?.adenocarcinomaDifferentiation ||
                ""
              }
              labelKey={"label"}
              gridColumnXs={12}
              gridColumnMd={4}
              gridColumnLg={4}
              isDisabled={PreoperativeStore.isReadOnly}
              iconSize={20}
              onChange={(value: string) =>
                onInputChange("adenocarcinomaDifferentiation", value)
              }
            />
            {PreoperativeStore.preoperativeIsInvalid
              ?.adenocarcinomaDifferentiation && (
              <Stack
                className="error-message"
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                marginTop={0.5}
              >
                <ErrorIcon sx={{ fontSize: 16 }} />
                <Typography variant="subtitle2">
                  Select a adenocarcinoma differentiation
                </Typography>
              </Stack>
            )}
          </Box>
        );
      case "squamousCellCa":
        return (
          <Box marginLeft={{ xs: 4, md: 0 }}>
            <Box className="input-label">
              <Typography variant="body2">
                Squamous cell CA differentiation
              </Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*
              </Typography>
              <Typography variant="body2">&nbsp;:</Typography>
            </Box>
            <RadioGroupSelectionInput
              options={AdenocarcinomaAndSquamousDifferentiationOptionsConstant}
              selectedValue={
                PreoperativeStore.preoperative?.squamousCellCaDifferentiation ||
                ""
              }
              labelKey={"label"}
              gridColumnXs={12}
              gridColumnMd={4}
              gridColumnLg={4}
              isDisabled={PreoperativeStore.isReadOnly}
              iconSize={20}
              onChange={(value: string) =>
                onInputChange("squamousCellCaDifferentiation", value)
              }
            />
            {PreoperativeStore.preoperativeIsInvalid
              ?.squamousCellCaDifferentiation && (
              <Stack
                className="error-message"
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                marginTop={0.5}
              >
                <ErrorIcon sx={{ fontSize: 16 }} />
                <Typography variant="subtitle2">
                  Select a squamous cell CA differentiation
                </Typography>
              </Stack>
            )}
          </Box>
        );
      case "other":
        return (
          <Box marginLeft={{ xs: 4, md: 0 }}>
            <InputTextField
              id="otherDetail"
              label="Other detail"
              placeholder="Other detail"
              type="text"
              value={PreoperativeStore.preoperative.otherDetail || ""}
              errorMessage="Others detail is invalid"
              isShowErrorMessage={
                PreoperativeStore.preoperativeIsInvalid?.otherDetail
              }
              isError={PreoperativeStore.preoperativeIsInvalid?.otherDetail}
              errorIconSize={16}
              isDisabled={PreoperativeStore.isReadOnly}
              isReadOnly={PreoperativeStore.isReadOnly}
              isRequire={true}
              handleOnChange={(value: string) =>
                onInputChange("otherDetail", value)
              }
            />
          </Box>
        );
      default:
        break;
    }
  };
  const onInputPreoperativeProcedureChange = (
    value: boolean,
    index: number
  ) => {
    const forms = [...PreoperativeStore.preoperativeProcedures];
    const formIsInvalid = PreoperativeStore.preoperativeIsInvalid;
    forms[index] = { ...forms[index], value };
    formIsInvalid.preoperativeProcedure = false;
    PreoperativeStore.onSetPreoperativeProcedures([...forms]);
    PreoperativeStore.onSetPreoperativeIsInvalid({ ...formIsInvalid });
  };

  return (
    <>
      <Box
        className="input-label"
        color={ColorsConstant.black}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
      >
        <Typography variant="h6">Malignant Case</Typography>
        <Typography className="input-label-require" variant="h6">
          &nbsp;*
        </Typography>
      </Box>
      <Box display="flex" marginTop={3}>
        <RadioSelection
          value="true"
          selectedValue={PreoperativeStore.preoperative?.malignantCase || ""}
          label="Yes"
          isDisabled={PreoperativeStore.isReadOnly}
          onChange={(value: any) => onInputChange("malignantCase", value)}
        />
        <RadioSelection
          value="false"
          selectedValue={PreoperativeStore.preoperative?.malignantCase || ""}
          label="No"
          isDisabled={PreoperativeStore.isReadOnly}
          onChange={(value: any) => onInputChange("malignantCase", value)}
        />
      </Box>
      {PreoperativeStore.preoperativeIsInvalid?.malignantCase && (
        <Stack
          className="error-message"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          marginTop={0.5}
        >
          <ErrorIcon sx={{ fontSize: 16 }} />
          <Typography variant="subtitle2">Select a malignant case</Typography>
        </Stack>
      )}
      {PreoperativeStore.preoperative?.malignantCase &&
        PreoperativeStore.preoperative?.malignantCase === "true" && (
          <Box marginTop={4}>
            <Box
              className="input-label"
              color={ColorsConstant.black}
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="h6">
                Malignancy information (ถ้าไม่เจาะ ไม่ต้องเติมค่า)
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {/* Preoperative CEA (ng/dL.) */}
              <Grid item marginTop={3} xs={12} md={6}>
                <InputTextField
                  id="cea"
                  label="Preoperative CEA (ng/dL.)"
                  placeholder="Preoperative CEA (ng/dL.)"
                  type="text"
                  value={String(PreoperativeStore.preoperative?.cea)}
                  isError={PreoperativeStore.preoperativeIsInvalid?.cea}
                  isShowErrorMessage={
                    PreoperativeStore.preoperativeIsInvalid?.cea
                  }
                  errorMessage="Preoperative CEA (ng/dL.) is invalid"
                  errorIconSize={16}
                  isDisabled={PreoperativeStore.isReadOnly}
                  isReadOnly={PreoperativeStore.isReadOnly}
                  isRequire={true}
                  handleOnChange={(value: string) =>
                    onInputChange("cea", value)
                  }
                />
              </Grid>
              {/* /.Preoperative CEA (ng/dL.) */}
            </Grid>

            {/* Preoperative tissue pathology */}
            <Box color={ColorsConstant.neutral} marginBottom={1} marginTop={4}>
              <Box className="input-label">
                <Typography variant="body2">
                  Preoperative tissue pathology
                </Typography>
                <Typography className="input-label-require" variant="body2">
                  &nbsp;*
                </Typography>
                <Typography variant="body2">&nbsp;:</Typography>
              </Box>
            </Box>
            {PreoperativeStore.preoperativeTissuePathologies?.map(
              (preoperativeTissuePathology, index) => {
                return (
                  <Grid key={index} container spacing={1} marginBottom={1}>
                    <Grid item xs={12} md={6} key={index}>
                      <CheckboxSelection
                        label={preoperativeTissuePathology.label}
                        isChecked={preoperativeTissuePathology.value}
                        isDisabled={PreoperativeStore.isReadOnly}
                        onChange={(value: boolean) =>
                          onInputPreoperativeTissuePathologyChange(value, index)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {preoperativeTissuePathology.value &&
                        preoperativeTissuePathologySubInput(
                          preoperativeTissuePathology.key
                        )}
                    </Grid>
                  </Grid>
                );
              }
            )}
            {PreoperativeStore.preoperativeIsInvalid
              ?.preoperativeTissuePathology && (
              <Stack
                className="error-message"
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                marginTop={0.5}
              >
                <ErrorIcon sx={{ fontSize: 16 }} />
                <Typography variant="subtitle2">
                  Select the preoperative tissue pathology
                </Typography>
              </Stack>
            )}
            {/* /.Preoperative tissue pathology */}

            <Grid container spacing={2}>
              {/* Preoperative procedure */}
              <Grid item xs={12} md={6} lg={4} marginTop={3}>
                <Box color={ColorsConstant.neutral} marginBottom={1}>
                  <Box className="input-label">
                    <Typography variant="body2">
                      Preoperative procedure
                    </Typography>
                    <Typography className="input-label-require" variant="body2">
                      &nbsp;*
                    </Typography>
                    <Typography variant="body2">&nbsp;:</Typography>
                  </Box>
                </Box>
                {PreoperativeStore.preoperativeProcedures?.map(
                  (preoperativeProcedure, index) => {
                    return (
                      <Box key={index} marginBottom={1}>
                        <CheckboxSelection
                          label={preoperativeProcedure.label}
                          isChecked={preoperativeProcedure.value}
                          isDisabled={PreoperativeStore.isReadOnly}
                          onChange={(value: boolean) =>
                            onInputPreoperativeProcedureChange(value, index)
                          }
                        />
                      </Box>
                    );
                  }
                )}
                {PreoperativeStore.preoperativeIsInvalid
                  ?.preoperativeProcedure && (
                  <Stack
                    className="error-message"
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    marginTop={0.5}
                  >
                    <ErrorIcon sx={{ fontSize: 16 }} />
                    <Typography variant="subtitle2">
                      Select the preoperative procedure
                    </Typography>
                  </Stack>
                )}
              </Grid>
              {/* /.Preoperative procedure */}

              {/* Neoadjuvant Chemo Regimens */}
              <Grid item xs={12} md={6} lg={4} marginTop={3}>
                <Box color={ColorsConstant.neutral} marginBottom={1}>
                  <Box className="input-label">
                    <Typography variant="body2">
                      Neoadjuvant chemo regimen
                    </Typography>
                    <Typography className="input-label-require" variant="body2">
                      &nbsp;*
                    </Typography>
                    <Typography variant="body2">&nbsp;:</Typography>
                  </Box>
                </Box>
                <RadioGroupSelectionInput
                  options={PreoperativeStore.neoadjuvantChemoRegimens}
                  selectedValue={
                    PreoperativeStore.preoperative?.neoadjuvantChemoRegimen ||
                    ""
                  }
                  labelKey={"label"}
                  gridSpacing={1}
                  gridColumnXs={12}
                  gridColumnMd={6}
                  gridColumnLg={6}
                  isDisabled={PreoperativeStore.isReadOnly}
                  iconSize={20}
                  onChange={(value: string) =>
                    onInputChange("neoadjuvantChemoRegimen", value)
                  }
                />
                {PreoperativeStore.preoperativeIsInvalid
                  ?.neoadjuvantChemoRegimen && (
                  <Stack
                    className="error-message"
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    marginTop={0.5}
                  >
                    <ErrorIcon sx={{ fontSize: 16 }} />
                    <Typography variant="subtitle2">
                      Select a neoadjuvant chemo regimen
                    </Typography>
                  </Stack>
                )}
              </Grid>
              {/* /.Neoadjuvant Chemo Regimens */}

              {/* Neoadjuvant XRT dose (cGy) */}
              <Grid item xs={12} md={6} lg={4} marginTop={3}>
                <InputTextField
                  id="neoadjuvantXrtDose"
                  label="Neoadjuvant XRT dose (cGy)"
                  placeholder="Neoadjuvant XRT dose (cGy)"
                  type="text"
                  value={
                    PreoperativeStore.preoperative.neoadjuvantXrtDose || ""
                  }
                  isDisabled={PreoperativeStore.isReadOnly}
                  isReadOnly={PreoperativeStore.isReadOnly}
                  isRequire={false}
                  handleOnChange={(value: string) =>
                    onInputChange("neoadjuvantXrtDose", value)
                  }
                />
              </Grid>
              {/* /.Neoadjuvant XRT dose (cGy) */}
            </Grid>

            {/* Preoperative procedure details */}
            <Box marginTop={4}>
              <InputTextField
                id="preoperativeProcedureDetails"
                label="Preoperative procedure details"
                placeholder="Preoperative procedure details"
                type="text"
                value={
                  PreoperativeStore.preoperative.preoperativeProcedureDetail ||
                  ""
                }
                errorIconSize={16}
                isDisabled={PreoperativeStore.isReadOnly}
                isReadOnly={PreoperativeStore.isReadOnly}
                isRequire={false}
                handleOnChange={(value: string) =>
                  onInputChange("preoperativeProcedureDetail", value)
                }
              />
            </Box>
            {/* /.Preoperative procedure details */}
          </Box>
        )}
    </>
  );
});

export default FormMalignant;
