import React, { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react";
import { Box, Grid, Typography } from "@mui/material";
import { useStores } from "../../../stores";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { DropdownTypes as DropdownTypesConstant } from "../../../constants/Dropdown";
import { CreateSurgeon as CreateSurgeonService } from "../../../services/Surgeon";
import { GetDropdownByType as GetDropdownByTypeService } from "../../../services/Dropdown";
import { Dropdown as DropdownModel } from "../../../models/Dropdown";
import {
  Surgeon as SurgeonModel,
  CreateBody as CreateBodySurgeonModel,
} from "../../../models/Surgeon";
import { CalculateTotalDateTimeHourMin as CalculateTotalDateTimeHourMinUtil } from "../../../utils/FormatDate";
import DropdownSelection from "../../dropdowns/Selection";
import InputTextField from "../../inputs/TextField";
import CheckboxGroupSelection from "../../inputs/CheckboxGroupSelection";
import DatetimePicker from "../../inputs/DatetimePicker";
import SearchDropdown from "../../dropdowns/Search";
import LoadingWholeScreen from "../../loading/WholeScreen";

interface Props {}

const FormAssistant = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();
  const [assistants, setAssistants] = useState<SurgeonModel[]>([]);
  const [assistantTypes, setAssistantTypes] = useState<DropdownModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!OperativeNoteStore.isReadOnly) {
      getDropdownSurgeonTypes();
    }
  }, [OperativeNoteStore.isReadOnly]);

  useEffect(() => {
    setAssistants([...OperativeNoteStore.surgeonsUser]);
  }, [OperativeNoteStore.surgeonsUser]);

  const onCreateSurgeon = async (body: CreateBodySurgeonModel, key: string) => {
    setIsLoading(true);
    const response: any = await CreateSurgeonService(body);
    if (response.success) {
      const responseGetAssistant: any =
        await OperativeNoteStore.onGetSurgeonsUser("");
      responseGetAssistant.success && onInputChange(key, response.data);
      setIsLoading(false);
    }
  };
  const getDropdownSurgeonTypes = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.operativeNoteSurgeonType
    );
    response.success && setAssistantTypes([...response.data]);
  };
  const onInputChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.assistants };
    const formIsInvalid: any = { ...OperativeNoteStore.assistantIsInvalid };
    form[key] = value;
    formIsInvalid[key] && (formIsInvalid[key] = false);
    OperativeNoteStore.onSetAssistant({ ...form });
    OperativeNoteStore.onSetAssistantIsInvalid({ ...formIsInvalid });
    if (key === "operativeStartAt") {
      value &&
        OperativeNoteStore.assistants.operativeStopAt &&
        onValidateDateRange(
          dayjs(value),
          dayjs(OperativeNoteStore.assistants.operativeStopAt)
        );
    } else if (key === "operativeStopAt") {
      value &&
        OperativeNoteStore.assistants.operativeStartAt &&
        onValidateDateRange(
          dayjs(OperativeNoteStore.assistants.operativeStartAt),
          dayjs(value)
        );
    }
  };
  const onInputAnesthesiaChange = (value: boolean, index: number) => {
    const forms: any = [...OperativeNoteStore.anesthesia];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetAnesthesia([...forms]);
  };
  const onValidateDateRange = (start: string | Dayjs, end: string | Dayjs) => {
    const formIsInvalid: any = { ...OperativeNoteStore.assistantIsInvalid };
    const totalOperativeTime = CalculateTotalDateTimeHourMinUtil(start, end);
    formIsInvalid.operativeStartAt = totalOperativeTime[0] === "-";
    formIsInvalid.operativeStopAt = totalOperativeTime[0] === "-";
    OperativeNoteStore.onSetAssistantIsInvalid({ ...formIsInvalid });
  };

  return (
    <>
      {isLoading && <LoadingWholeScreen isShowDescription={true} />}
      <Box
        id={ColorectalSurgeryOperativeNoteFormConstant.assistant}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Assistant</Typography>
      </Box>
      {/* Assistant one */}
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} md={6}>
          <SearchDropdown
            id="operative-note-assistant-one"
            label="Assistant"
            placeholder="Assistant"
            optionLabelKey="name"
            textNoResultAddButton="Add Assistant"
            options={assistants}
            selected={
              OperativeNoteStore.assistants?.assistantOneNew?.id
                ? OperativeNoteStore.assistants?.assistantOneNew
                : null
            }
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) => onInputChange("assistantOneNew", value)}
            onInputChange={(value: string) => {}}
            onClickAdd={(name: string) =>
              onCreateSurgeon({ name }, "assistantOneNew")
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">As type :</Typography>
          </Box>
          <DropdownSelection
            options={assistantTypes}
            selected={OperativeNoteStore.assistants?.assistantOneType}
            optionKey="value"
            valueKey="id"
            placeholder="As type"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) =>
              onInputChange("assistantOneType", value)
            }
          />
        </Grid>
      </Grid>
      {/* /.Assistant one */}

      {/* Assistant two */}
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} md={6}>
          <SearchDropdown
            id="operative-note-assistant-two"
            label="Assistant 2"
            placeholder="Assistant 2"
            optionLabelKey="name"
            textNoResultAddButton="Add Assistant"
            options={assistants}
            selected={
              OperativeNoteStore.assistants?.assistantTwoNew?.id
                ? OperativeNoteStore.assistants?.assistantTwoNew
                : null
            }
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) => onInputChange("assistantTwoNew", value)}
            onInputChange={(value: string) => {}}
            onClickAdd={(name: string) =>
              onCreateSurgeon({ name }, "assistantTwoNew")
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">As type 2 :</Typography>
          </Box>
          <DropdownSelection
            options={assistantTypes}
            selected={OperativeNoteStore.assistants?.assistantTwoType}
            optionKey="value"
            valueKey="id"
            placeholder="As type 2"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) =>
              onInputChange("assistantTwoType", value)
            }
          />
        </Grid>
      </Grid>
      {/* /.Assistant two */}

      {/* Assistant three */}
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} md={6}>
          <SearchDropdown
            id="operative-note-assistant-three"
            label="Assistant 3"
            placeholder="Assistant 3"
            optionLabelKey="name"
            textNoResultAddButton="Add Assistant"
            options={assistants}
            selected={
              OperativeNoteStore.assistants?.assistantThreeNew?.id
                ? OperativeNoteStore.assistants?.assistantThreeNew
                : null
            }
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) =>
              onInputChange("assistantThreeNew", value)
            }
            onInputChange={(value: string) => {}}
            onClickAdd={(name: string) =>
              onCreateSurgeon({ name }, "assistantThreeNew")
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">As type 3 :</Typography>
          </Box>
          <DropdownSelection
            options={assistantTypes}
            selected={OperativeNoteStore.assistants?.assistantThreeType}
            optionKey="value"
            valueKey="id"
            placeholder="As type 3"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) =>
              onInputChange("assistantThreeType", value)
            }
          />
        </Grid>
      </Grid>
      {/* /.Assistant three */}

      {/* Other assistant */}
      <Box marginTop={3}>
        <InputTextField
          id="otherAssistant"
          label="Other assistant"
          placeholder="Other assistant"
          type="text"
          value={OperativeNoteStore.assistants?.otherAssistant || ""}
          isDisabled={OperativeNoteStore.isReadOnly}
          isReadOnly={OperativeNoteStore.isReadOnly}
          isRequire={false}
          handleOnChange={(value: string) =>
            onInputChange("otherAssistant", value)
          }
        />
      </Box>
      {/* /.Other assistant */}

      {/* Anesthesia */}
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} md={4}>
          <Box className="input-label">
            <Typography variant="body2">Anesthesia :</Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.anesthesia}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={12}
            gridColumnLg={12}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputAnesthesiaChange(value, index)
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputTextField
            id="anesthesiologist"
            label="Anesthesiologist"
            placeholder="Anesthesiologist"
            type="text"
            value={OperativeNoteStore.assistants?.anesthesiologist || ""}
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isError={OperativeNoteStore.assistantIsInvalid?.anesthesiologist}
            isShowErrorMessage={
              OperativeNoteStore.assistantIsInvalid?.anesthesiologist
            }
            errorMessage="Anesthesiologist is invalid"
            errorIconSize={16}
            isRequire={true}
            handleOnChange={(value: string) =>
              onInputChange("anesthesiologist", value)
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputTextField
            id="scrubNurse"
            label="Scrub nurse"
            placeholder="Scrub nurse"
            type="text"
            value={OperativeNoteStore.assistants?.scrubNurse || ""}
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("scrubNurse", value)
            }
          />
        </Grid>
      </Grid>
      {/* /.Anesthesia */}

      {/* Operative time */}
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} md={3}>
          <DatetimePicker
            label="Operative start at"
            isRequire={true}
            dateSelected={OperativeNoteStore.assistants?.operativeStartAt}
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isError={OperativeNoteStore.assistantIsInvalid?.operativeStartAt}
            isShowErrorMessage={
              OperativeNoteStore.assistantIsInvalid?.operativeStartAt
            }
            errorIconSize={16}
            errorMessage="Operative start at is invalid"
            onDateSelected={(date: any) =>
              onInputChange("operativeStartAt", date)
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatetimePicker
            label="Operative stop at"
            isRequire={true}
            dateSelected={OperativeNoteStore.assistants?.operativeStopAt}
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isError={OperativeNoteStore.assistantIsInvalid?.operativeStopAt}
            isShowErrorMessage={
              OperativeNoteStore.assistantIsInvalid?.operativeStopAt
            }
            errorIconSize={16}
            errorMessage="Operative stop at is invalid"
            onDateSelected={(date: any) =>
              onInputChange("operativeStopAt", date)
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            id="totalOperativeTime"
            label="Total operative time"
            placeholder="Total operative time"
            type="text"
            value={
              (OperativeNoteStore.assistants?.operativeStartAt &&
                OperativeNoteStore.assistants?.operativeStopAt &&
                String(
                  CalculateTotalDateTimeHourMinUtil(
                    OperativeNoteStore.assistants?.operativeStartAt,
                    OperativeNoteStore.assistants?.operativeStopAt
                  )
                )) ||
              ""
            }
            isDisabled={true}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) => {}}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            id="bloodLoss"
            label="Blood loss (cc.)"
            placeholder="Blood loss (cc.)"
            type="number"
            value={String(OperativeNoteStore.assistants?.bloodLoss) || ""}
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("bloodLoss", value)
            }
          />
        </Grid>
      </Grid>
    </>
  );
});

export default FormAssistant;
