import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Box, Grid, Typography } from "@mui/material";
import { useStores } from "../../../stores";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { DropdownTypes as DropdownTypesConstant } from "../../../constants/Dropdown";
import { GetDropdownByType as GetDropdownByTypeService } from "../../../services/Dropdown";
import { CreateSurgeon as CreateSurgeonService } from "../../../services/Surgeon";
import {
  Surgeon as SurgeonModel,
  CreateBody as CreateBodySurgeonModel,
} from "../../../models/Surgeon";
import { Dropdown as DropdownModel } from "../../../models/Dropdown";
import DropdownSelection from "../../dropdowns/Selection";
import SearchDropdown from "../../dropdowns/Search";
import LoadingWholeScreen from "../../loading/WholeScreen";

interface Props {}

const FormSurgeons = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();
  const [surgeonTypes, setSurgeonTypes] = useState<DropdownModel[]>([]);
  const [surgeons, setSurgeons] = useState<SurgeonModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!OperativeNoteStore.isReadOnly) {
      OperativeNoteStore.onGetSurgeonsUser("");
      getDropdownSurgeonTypes();
    }
  }, [OperativeNoteStore, OperativeNoteStore.isReadOnly]);

  useEffect(() => {
    setSurgeons([...OperativeNoteStore.surgeonsUser]);
  }, [OperativeNoteStore.surgeonsUser]);

  const getDropdownSurgeonTypes = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.operativeNoteSurgeonType
    );
    response.success && setSurgeonTypes([...response.data]);
  };
  const onCreateSurgeon = async (body: CreateBodySurgeonModel, key: string) => {
    setIsLoading(true);
    const response: any = await CreateSurgeonService(body);
    if (response.success) {
      const responseGetSurgeon: any =
        await OperativeNoteStore.onGetSurgeonsUser("");
      responseGetSurgeon.success && onInputChange(key, response.data);
      setIsLoading(false);
    }
  };
  const onInputChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.surgeons };
    form[key] = value;
    OperativeNoteStore.onSetSurgeons({ ...form });
  };

  return (
    <>
      {isLoading && <LoadingWholeScreen isShowDescription={true} />}
      <Box
        id={ColorectalSurgeryOperativeNoteFormConstant.surgeons}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Surgeons</Typography>
      </Box>
      {/* Surgeon one */}
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} md={6}>
          <SearchDropdown
            id="operative-note-surgeon-one"
            label="Surgeon"
            placeholder="Surgeon"
            optionLabelKey="name"
            textNoResultAddButton="Add Surgeon"
            options={surgeons}
            selected={
              OperativeNoteStore.surgeons?.surgeonOneNew?.id
                ? OperativeNoteStore.surgeons?.surgeonOneNew
                : null
            }
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) => onInputChange("surgeonOneNew", value)}
            onInputChange={(value: string) => {}}
            onClickAdd={(name: string) =>
              onCreateSurgeon({ name }, "surgeonOneNew")
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">Sg type :</Typography>
          </Box>
          <DropdownSelection
            options={surgeonTypes}
            selected={OperativeNoteStore.surgeons?.surgeonOneType}
            optionKey="value"
            valueKey="id"
            placeholder="Sg type"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) => onInputChange("surgeonOneType", value)}
          />
        </Grid>
      </Grid>
      {/* /.Surgeon one */}

      {/* Surgeon two */}
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} md={6}>
          <SearchDropdown
            id="operative-note-surgeon-two"
            label="Surgeon 2"
            placeholder="Surgeon 2"
            optionLabelKey="name"
            textNoResultAddButton="Add Surgeon"
            options={surgeons}
            selected={
              OperativeNoteStore.surgeons?.surgeonTwoNew?.id
                ? OperativeNoteStore.surgeons?.surgeonTwoNew
                : null
            }
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) => onInputChange("surgeonTwoNew", value)}
            onInputChange={(value: string) => {}}
            onClickAdd={(name: string) =>
              onCreateSurgeon({ name }, "surgeonTwoNew")
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">Sg type 2 :</Typography>
          </Box>
          <DropdownSelection
            options={surgeonTypes}
            selected={OperativeNoteStore.surgeons?.surgeonTwoType}
            optionKey="value"
            valueKey="id"
            placeholder="Sg type 2"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) => onInputChange("surgeonTwoType", value)}
          />
        </Grid>
      </Grid>
      {/* /.Surgeon two */}

      {/* Surgeon three */}
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} md={6}>
          <SearchDropdown
            id="operative-note-surgeon-three"
            label="Surgeon 3"
            placeholder="Surgeon 3"
            optionLabelKey="name"
            textNoResultAddButton="Add Surgeon"
            options={surgeons}
            selected={
              OperativeNoteStore.surgeons?.surgeonThreeNew?.id
                ? OperativeNoteStore.surgeons?.surgeonThreeNew
                : null
            }
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) => onInputChange("surgeonThreeNew", value)}
            onInputChange={(value: string) => {}}
            onClickAdd={(name: string) =>
              onCreateSurgeon({ name }, "surgeonThreeNew")
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">Sg type 3 :</Typography>
          </Box>
          <DropdownSelection
            options={surgeonTypes}
            selected={OperativeNoteStore.surgeons?.surgeonThreeType}
            optionKey="value"
            valueKey="id"
            placeholder="Sg type 3"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) =>
              onInputChange("surgeonThreeType", value)
            }
          />
        </Grid>
      </Grid>
      {/* /.Surgeon three */}
    </>
  );
});

export default FormSurgeons;
