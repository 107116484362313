import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { Stack, Typography, Box, Grid, Button } from "@mui/material";
import AuthenticatedLayout from "../../layouts/Authenticated";
// import Switcher from "../../components/inputs/Switcher";
import FormPatient from "../../components/patients/Form";
import LoadingWholeScreen from "../../components/loading/WholeScreen";
import ModalFail from "../../components/modals/Fail";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";
import { Paths as PathsConstant } from "../../constants/Route";
import { ScrollToElementId as ScrollToElementIdUtil } from "../../utils/ScrollToElement";
import { useStores } from "../../stores";
import { CreatePatient as CreatePatientService } from "../../services/Patient";

interface Props {}

const Management = observer((props: Props) => {
  const navigate = useNavigate();
  const { PatientStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowFailModal, setIsShowFailModal] = useState(false);

  useEffect(() => {
    PatientStore.onSetIsReadOnly(false);
  }, [PatientStore]);

  // const onFormChange = (key: string, value: any) => {
  //   const formObj: any = { ...PatientStore.patient };
  //   const formIsInvalidObj: any = { ...PatientStore.patientIsInvalid };
  //   formObj[key] = value;
  //   formIsInvalidObj[key] = false;
  //   PatientStore.onSetPatient(formObj);
  //   PatientStore.onSetPatientIsInvalid(formIsInvalidObj);
  // };
  const onSubmit = async () => {
    const formIsValid = PatientStore.onValidatePatientForm();
    const body = PatientStore.onGetPatientBody();
    const formIsInvalidObj: any = { ...PatientStore.patientIsInvalid };
    setIsLoading(true);
    if (formIsValid) {
      const response = await CreatePatientService(body);
      if (response.success) {
        onNavigateToPatientList();
      } else {
        if (response.data.response?.data.message === "Patient already exists") {
          formIsInvalidObj.hn = true;
          PatientStore.onSetPatientIsInvalid(formIsInvalidObj);
          ScrollToElementIdUtil("patientForm");
        } else {
          setIsShowFailModal(true);
        }
      }
    } else {
      ScrollToElementIdUtil("patientForm");
    }
    setIsLoading(false);
  };
  const onNavigateToPatientList = () => {
    navigate(PathsConstant.patientList);
  };

  return (
    <AuthenticatedLayout>
      <>
        {isLoading && <LoadingWholeScreen isShowDescription={true} />}
        <Stack
          flexDirection="row"
          alignItems="center"
          paddingBottom={1}
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
        >
          <Typography variant="h5">New Patient</Typography>
          {/* <Box marginLeft="auto">
            <Switcher
              isChecked={PatientStore.patient?.isActive}
              isDisabled={PatientStore.isReadOnly}
              onChange={(isChecked) => {
                onFormChange("isActive", isChecked);
              }}
            />
          </Box> */}
        </Stack>
        <Box marginY={4} id="patientForm">
          <FormPatient />
        </Box>
        <Grid
          container
          spacing={2}
          marginTop={2}
          marginBottom={4}
          justifyContent="center"
          direction={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button variant="outlined" onClick={onNavigateToPatientList}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button variant="contained" onClick={onSubmit}>
              Create
            </Button>
          </Grid>
        </Grid>
        <ModalFail
          isOpen={isShowFailModal}
          title="Create patient"
          description="Failed to create new patient. Please try again."
          onClose={() => {
            setIsShowFailModal(false);
          }}
        />
      </>
    </AuthenticatedLayout>
  );
});

export default Management;
