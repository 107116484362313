import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { AddAPhoto as AddAPhotoIcon } from "@mui/icons-material";
import "../../assets/styles/Upload.scss";
import { FileUploader } from "react-drag-drop-files";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";
import ModalFail from "../modals/Fail";

interface Props {
  id: string;
  titleModal: string;
  onChange: (file: any) => void;
}

const DragAndDropImage = (props: Props) => {
  const fileTypes = [
    "JPG",
    "JPEG",
    "PNG",
    "HEIC",
    "MP4",
    "MOV",
    "HEIF",
    "H.264",
    "H.265",
    "MPEG-4",
    "M-JPEG",
    "HEVC",
    "WMV",
    "AVI",
  ];
  const [isShowFailModal, setIsShowFailModal] = useState(false);
  const [messageFileTypeFail, setMessageFileTypeFail] = useState("");
  const [messageFileSizeFail, setMessageFileSizeFail] = useState("");

  const handleChange = (files: any) => {
    props.onChange(files);
  };
  const handleOnTypeError = () => {
    setMessageFileTypeFail(
      "The uploaded file must be a image or video file type (jpg, jpeg png HEIC mp4 MOV HEIF H.264 H.265 MPEG-4 M-JPEG HEVC WMV and AVI)"
    );
    setIsShowFailModal(true);
  };
  const handleOnSizeError = () => {
    setMessageFileSizeFail("The uploaded file size must not exceed 20 MB");
    setIsShowFailModal(true);
  };
  const onCloseModalFail = () => {
    setIsShowFailModal(false);
    setMessageFileTypeFail("");
    setMessageFileSizeFail("");
  };

  return (
    <>
      <FileUploader
        name="file"
        types={fileTypes}
        multiple={true}
        maxSize={20}
        handleChange={handleChange}
        onTypeError={handleOnTypeError}
        onSizeError={handleOnSizeError}
      >
        <Box className="file-upload-container">
          <Box className="file-upload-card">
            <AddAPhotoIcon sx={{ fontSize: 24 }} />
            <Typography variant="subtitle2" marginY={1}>
              Drag &amp; Drop
            </Typography>
            <Typography variant="caption">
              You files here or Browse to upload
            </Typography>
            <Typography
              variant="caption"
              sx={{ color: ColorsConstant.selectedIcon }}
            >
              Only JPEG JPG PNG ans MP4 files with max size of 20MB
            </Typography>
          </Box>
        </Box>
      </FileUploader>
      <ModalFail
        isOpen={isShowFailModal}
        title={props.titleModal}
        description={messageFileTypeFail || messageFileSizeFail}
        onClose={onCloseModalFail}
      />
    </>
  );
};

export default DragAndDropImage;
